<template>
    <div class="home login">
        <div id="dne"></div>
        <Header />

        <div id="login">
            <section class="login-page">
                <div class="skew-right no-skew-mobile">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-lg-5">
                                <div class="box-login">
                                    <h2 class="title-secondary" v-if="loginRemember ===  false">
                                        {{ getMyLanguage("login", "login.access-account") }}
                                    </h2>

                                    <h2 class="title-secondary" v-if="loginRemember ===  true">
                                        {{ getMyLanguage("login", "login.remember-password") }}
                                    </h2>
                                    
                                    <div class="LoginOne" v-if="loginOne === true">
                                        <input type="text" class="form formEmailLogin" id="emailLogin"
                                            :placeholder="getMyLanguage('login','login.placeholder.login')"
                                            :class='{error: stepLogin.emailRegex == "error"}'
                                            v-model="sign.username"
                                            @keyup.enter="regexEmail('login')"
                                            @keyup="regexEmail()"
                                            @blur="regexEmail('check')">

                                        <div class="enterPass">
                                            <input type="password" class="form formPassLogin"
                                                :placeholder="getMyLanguage('login','login.placeholder.password')"
                                                @keyup.enter="regexEmail('login')"
                                                v-if="!showPass"
                                                v-model="sign.password">

                                            <input type="text" class="form formPassLogin"
                                                :placeholder="getMyLanguage('login','login.placeholder.password')"
                                                @keyup.enter="regexEmail('login')"
                                                v-if="showPass"
                                                v-model="sign.password">

                                            <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                                            <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                                        </div>

                                        <div class="btn-remember-password"
                                            v-on:click="loginResetPass"
                                            v-if="!$store.state.statusLogin">

                                            {{ getMyLanguage("login", "login.remember-password") }}
                                        </div>
                                        
                                        <div class="btn-primary btn-disabled"
                                            v-if="stepLogin.emailRegex == 'invalid' || stepLogin.emailRegex == 'error' || sign.username.length <= 5 || sign.password.length <= 7">
                                            
                                            {{ getMyLanguage("login","login.login") }}
                                        </div>

                                        <div class="btn-primary"
                                            @click="loginPlatform()"
                                            v-if="stepLogin.emailRegex == 'validated' && !stepLogin.load && sign.username.length > 5 && sign.password.length > 7">

                                            {{ getMyLanguage("login","login.login") }}
                                        </div>

                                        <div class="btn-primary btn-loading" v-if="stepLogin.load === true"></div>
                                    </div>
                                    
                                    <div class="loginRemember" v-if="loginRemember && (!rememberSend || !rememberError)">
                                        <input type="text" class="form formEmailLogin" id="emailRemember" required
                                            v-model="emailRemember.email"
                                            :placeholder='getMyLanguage("login","login.remember-email-registered")'>

                                        <button class="btn-primary" @click="rememberPass()" v-if="!emailRemember.load">
                                            {{ getMyLanguage("login", "login.remember") }}
                                        </button>
                                        
                                        <div class="btn-primary load" v-if="emailRemember.load"></div>
                                    </div>

                                    <div class="rememberPass rememberSuccess" v-if="!loginRemember && rememberSend">
                                        <p>{{getMyLanguage("login","login.remember-password.success")}}</p>
                                    </div>
                                    
                                    <div class="rememberPass rememberError" v-if="loginRemember && rememberError">
                                        <p>{{getMyLanguage("login","login.remember-password.error")}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>



<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Header.vue';
    import apiPlatform from '@/config/apiPlatform.js'
    import axios from 'axios';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        components: {
            Header
        },

        data(){
            return{
                VUE_APP_MERCHANT_API: process.env.VUE_APP_MERCHANT_API,

                logoutActive: false,
                loginOne: true,
                loginTwo: false,
                loginRemember: false,
                showPass: false,

                emailRemember: {
                    load: false,
                    email: ''
                },

                rememberSend: false,
                rememberError: false,
                dataAccount: [],

                sign: {
                    username: "",
                    password: ""
                },

                stepLogin: {
                    emailRegex: "",
                    load: false
                },

                validate: {
                    tooltipUsername: false,
                    tooltipPassword: false,
                },

                statusUserLogin: {
                    logged: this.$store.state.statusLogin
                },

                destiny: "client-space"
            }
        },

        async mounted(){
            const urlParams = new URLSearchParams(window.location.search);
            
            if ( urlParams.size > 0 ){
                this.destiny = urlParams.get('destiny')
            }
        },

        methods: {
            regexEmail(type){
                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (regex.test(this.sign.username)) {
                    if ( type == "login" ){
                        this.stepLogin.emailRegex = "validated"
                        this.loginPlatform()
                    } else {
                        this.stepLogin.emailRegex = "validated"
                    }
                } else{
                    if ( type == "check" ){
                        this.stepLogin.emailRegex = "error"
                    } else {
                        this.stepLogin.emailRegex = "invalid"
                    }
                }
            },

            loginPlatform(){
                const loginPlatform = this.sign;
                this.stepLogin.load = true

                apiPlatform.post("/api/v1/platform/account/login", loginPlatform)
                .then(response => {
                    localStorage.setItem('accessToken', response.data.accessToken);
                    localStorage.setItem('codeLanguage', response.data.profile.account_language_alpha_2);
                    localStorage.setItem('flagLanguage', response.data.profile.account_language_flag);

                    this.flagSelected = localStorage.getItem('codeLanguage')
                    
                    if ( response.data.profile.account_type_code == "account_type_company" ){
                        this.loadListStore(response.data.accessToken)
                    } else {
                        window.location.href = `/${this.flagSelected}/client-space`
                    }
                })
                .catch(error => {
                    this.stepLogin.load = false

                    console.clear()
                    console.log(error.message);

                    $('.LoginOne').addClass('erroLogin');

                    setTimeout(() => {
                        $('.LoginOne').removeClass('erroLogin');
                    }, 6000);

                    let errorsLogin = JSON.stringify(error.response.status)
                    this.loginErrors = errorsLogin

                    if ( errorsLogin == 400 ){
                        this.$toast.error(this.getMyLanguage("login","login-password-error"));
                    } else{
                        this.$toast.error(this.getMyLanguage("login","login-error-authentication"));
                    }

                    setTimeout(this.$toast.clear, 6000)
                })
            },

            closeBoxLogin: function(){
                this.loginActive = false,
                this.loginOne = true,
                this.loginTwo = false,
                this.loginRemember = false
            },

            loginSMS: function(){
                this.loginOne = false,
                this.loginTwo = true
            },
            
            loginResetPass: function(){
                this.loginRemember = true,
                this.loginOne = false,
                this.loginTwo = false
            },


            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            rememberPass(){
                this.emailRemember.load = true
                
                apiPlatform.post("/api/v1/platform/account/password/reset", this.emailRemember)
                .then(response => {
                    this.registerId = response.data.id;
                    this.rememberSend = true
                    this.loginRemember = false

                })
                .catch(error => {
                    this.emailRemember.load = false
                    this.rememberError = true

                    console.clear()
                    console.log(error.message);
                })
            },

            // LOAD STORE LIST
            async loadListStore(token){
                const apiMerchantListStore = axios.create({
                    baseURL: this.VUE_APP_MERCHANT_API,
                    headers: { 'Authorization': `bearer ${token}` }
                });

                await apiMerchantListStore.get('/api/v1/market/store/list')
                .then(response => {
                    this.dataMerchant = response.data

                    localStorage.setItem("storeReference", this.dataMerchant[0].store.reference)
                    localStorage.setItem("storeName", this.dataMerchant[0].store.name)
                    localStorage.setItem("companyReference", this.dataMerchant[0].company_reference)
                    localStorage.setItem("companyAccountId", this.dataMerchant[0].company_account_id)
                    localStorage.setItem("companyId", this.dataMerchant[0].company_id)

                    setTimeout(() => {
                        window.location.href = `/${this.flagSelected}/client-space`
                    }, 300);
                })
                .catch(error => {
                    console.log(error)

                    this.$toast.error(this.getMyLanguage('box-error','msg-error'))
                })
            },
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.login");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>


<style src="./custom-login.scss" lang="scss" scoped />