<template>
    <div>
        <header :class="{develop: VUE_APP_DEVELOP == 'develop'}">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-4 col-xl-2 col-logo">
                        <a :href='"/" + flagSelected + "#dne"' class="logo">
                            <img src="@/assets/images/header/logo-dne-token.svg" alt="DNE Token">
                        </a>
                    </div>

                    <div class="col-8 col-xl-10">
                        <div class="row-master">
                            <div class="col-menu">
                                <MenuInternational />
                            </div>

                            <div class="col-lang-login">
                                <Login />
                                <SelectLanguage />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>


        <!-- VALIDATE ACCOUNT -->
        <div class="validate-account" v-if="$store.state.statusLogin && $store.state.profile.account_status !== 'Verified' && loadedAccount && !shouldHideValidateAccount">
            <div class="container-fluid">
                <div class="cont">
                    <p>{{ getMyLanguage("ValidateAccount","message") }}</p>
                    
                    <div class="btn-validade-account" @click="checkAccount()">
                        {{ getMyLanguage("ValidateAccount","cta-action") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    // import apiMerchant from '@/config/apiMerchant.js'

    import MenuInternational from '@/components/MenuInternational.vue';
    import SelectLanguage from '@/components/SelectLanguage.vue';
    import Login from '@/components/Login.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            MenuInternational,
            SelectLanguage,
            Login
        },

        data (){
            return {
                flagSelected: "en",
                loadedAccount: false,

                hasToken: "",
                token: "",
                isMerchant: false,

                hiddenRouteNames: [
                    'Compliance',
                    'ComplianceCompany',
                    'UboPerson',
                    'UboCompany'
                ],

                VUE_APP_DEVELOP: process.env.VUE_APP_DEVELOP
            }
        },

        async mounted(){
            this.checkToken()

            setTimeout(() => {
                this.loadedAccount = true
            }, 3000);
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
            
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            checkAccount(){
                window.location.href = `/${this.flagSelected}/client-space/purchase`
            },

            checkToken(){
                this.hasToken = localStorage.getItem("accessToken")

                if ( this.hasToken ){
                    var base64Url = localStorage.getItem("accessToken").split('.')[1];
                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    }).join(''));

                    this.token = JSON.parse(jsonPayload)

                    if ( this.token.permissions.filter(access => access == "dne:market:merchant").length ){
                        // this.loadListStore()
                    }
                }
            },

            // async loadListStore(){
            //     await apiMerchant.get('/api/v1/market/store/list')
            //     .then(response => {
            //         this.dataMerchant = response.data

            //         let storeREF = localStorage.getItem("storeReference")
            //         let companyREF = localStorage.getItem("companyReference")

            //         if ( storeREF == "0" || companyREF == "0" ){
            //             localStorage.setItem("storeReference", this.dataMerchant[0].store.reference)
            //             localStorage.setItem("storeName", this.dataMerchant[0].store.name)
            //             localStorage.setItem("companyReference", this.dataMerchant[0].company_reference)
            //             localStorage.setItem("companyAccountId", this.dataMerchant[0].company_account_id)
            //             localStorage.setItem("companyId", this.dataMerchant[0].company_id)

            //         }
            //     })
            //     .catch(error => {
            //         console.log(error)
            //     })
            // },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        computed: {
            shouldHideValidateAccount() {
                return this.hiddenRouteNames.includes(this.$route.name);
            }
        }
    }
</script>

<style src="@/assets/scss/_header.scss" lang="scss" scoped />