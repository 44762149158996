<template>
<div class="main">
    <router-view/>

    <div class="redirect"></div>

    <Footer />
    <CookieConsent />
</div>

</template>

<script>
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    import getMyContentLanguage from "@/services/contentLanguage.js";
    
    import apiInternational from '@/config/apiInternational.js'
    import apiPlatform from '@/config/apiPlatform.js'

    // import { handleError } from '@/services/errorHandler.js'
    
    export default {
        components: {
            Footer,
            CookieConsent
        },

        data: function () {
            return {
                language: 'en',
                group: 'dne-platform',

                dataLanguagess: [],
                dataCountries: [],

                dataPhases: [],
                dataPhaseCurrent: [],
                flagSelected: 'en',
                flagImg: '51021d86-49e9-4beb-804b-dfadf7a51300',
            }
        },

        beforeMount(){
            this.loginStatus();
            this.flagUrl()
        },

        async mounted(){
            this.checkRedirect()

            // LANGUAGES
            const resumeLanguages = await apiPlatform.get('api/v1/platform/language/dne_international')
            this.dataLanguages = resumeLanguages.data
            this.$store.commit('allLanguages',this.dataLanguages)

            this.flagImg = this.dataLanguages.filter(lang => lang.code === this.flagSelected)[0].flag
            this.$store.commit('preLanguageFlag',this.flagImg)

            // COUNTRIES
            const resumeCountries = await apiPlatform.get('api/v1/platform/country')
            this.dataCountries = resumeCountries.data
            this.$store.commit("allCountries",this.dataCountries)

            // const resumeLanguage = await apiPlatform.get('api/v1/platform/language/dne_international')
            // this.dataLanguages = resumeLanguage.data
            // this.$store.commit('allLanguages',this.dataLanguages)

            // this.flagImg = this.dataLanguages.filter(lang => lang.code === this.flagSelected)[0].flag
            // this.$store.commit('preLanguageFlag',this.flagImg)

            // PHASES
            const resumePhases = await apiInternational.get('/api/v2/ico/phase')
            this.dataPhases = resumePhases.data
            this.$store.commit('phases',this.dataPhases)

            // PHASE CURRENT
            const resumePhaseCurrent = await apiInternational.get('/api/v2/ico/phase/current')
            this.dataPhaseCurrent = resumePhaseCurrent.data
            this.$store.commit('phaseCurrent',this.dataPhaseCurrent)
        },

        methods: {
            checkRedirect(){
                window.onload = () => {
                    if (window.location.hash.includes("redirect")) {
                        history.replaceState(null, "", window.location.pathname);

                        document.body.classList.add("load-redirect");

                        setTimeout(() => {
                            document.body.classList.remove("load-redirect");
                        }, 5000);
                    }
                }
            },

            loginStatus(){
                const tokenValue = localStorage.getItem('accessToken')

                if ( tokenValue != undefined || tokenValue != null ){
                    apiPlatform.get('/api/v1/platform/account/profile/')
                    .then(response => {
                        this.$store.commit('profile',response.data)
                        this.$store.commit('loginStatus', true)
                    })
                    .catch(error => {
                        // handleError(error, this.flagSelected);

                        console.log(JSON.stringify(error))
                        let errorsLogin = JSON.stringify(error.response.status)
                        this.loginErrors = errorsLogin

                        if ( errorsLogin == 401 || errorsLogin == 403 ){
                            localStorage.removeItem('accessToken');
                        }
                    });
                }

                // this.$store.commit('loginStatus', false)
            },

            flagUrl(){
                let code = String(window.location.pathname).split("/")[1];
                let flag = '51021d86-49e9-4beb-804b-dfadf7a51300'
                let language = "English"

                if ( !code ) {
                    code = 'en',
                    this.$store.commit('preLanguageFlag',flag)
                    this.$store.commit('preLanguage',code)
                    localStorage.setItem('codeLanguage',code)
                    localStorage.setItem('flagLanguage','51021d86-49e9-4beb-804b-dfadf7a51300');
                } else{
                    this.flagSelected = code
                    this.$store.commit('preLanguage',code)
                    this.$store.commit('preLanguageName',language)
                }

                if (this.flagSelected === "ar"){
                    document.body.classList.add('ar');
                }
            },

            changeLanguage(parLanguage){
                this.language = parLanguage;
                let url = String(window.location.pathname).split("/");
                
                url[1] = parLanguage;
                window.location.href = `${url.join('/')}`;
            },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        }
    }
</script>

<style src="@/assets/scss/_custom-app.scss" lang="scss" />

<style lang="scss">
    body.load-redirect{
        .redirect{
            display: none;
            position: fixed;
            z-index: 9999;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba($color: #fff, $alpha: 0.85);
            opacity: 0;
            animation: outLoadBg 1s ease-out;
            
            &:before, &:after{
                content: "";
                display: block;
                position: absolute;
                opacity: 0;
                transform: scale(1);
                animation: outLoad 1s ease-out;
            }
    
            &:before{
                width: 100px;
                height: 100px;
                top: calc(50% - 50px);
                left: calc(50% - 50px);
                background: url(~@/assets/images/icons/dne-symbol.svg) no-repeat center;
                background-size: 100% auto;
            }
    
            &:after{
                width: 200px;
                height: 200px;
                top: calc(50% - 100px);
                left: calc(50% - 100px);
                background: url(~@/assets/images/icons/loading-secondary.svg) no-repeat center;
                background-size: 100% auto;
            }
        }

        @keyframes outLoadBg {
            0%{opacity: 1; display: block;}
            95%{opacity: 1; display: block;}
            100%{opacity: 0; display: none;}
        }

        @keyframes outLoad {
            0%{opacity: 1; transform: scale(1);}
            75%{opacity: 1; transform: scale(1);}
            100%{opacity: 0; transform: scale(1.2);}
        }
    }
</style>