<template>
    <div class="home compliance-page">
        <div id="dne"></div>
        <Header />

        <section class="compliance">

            <div class="load load-white load-200" v-if="!showContent"></div>

            <div class="container" v-if="showContent && errorsGet.dataAccount.data != false">
                <div class="box-error" :class="`error-${errorsGet.dataAccount.code}`">
                    <h3 class="title-secondary">
                        {{ getMyLanguage("box-error", "title-error") }}
                    </h3>
                    
                    <p v-if="errorsGet.dataAccount.data == 'international_company_member_not_found'">
                        {{ getMyLanguage("box-error","msg-error-403") }}
                    </p>

                    <p v-else>
                        {{ getMyLanguage("box-error","msg-error") }}
                    </p>
                </div>
            </div>

            <div class="container" v-if="showContent && !errorsGet.dataAccount.data">
                <h2 class="title-secondary">
                    {{getMyLanguage("compliance","compliance.title")}}
                </h2>
                
                <div class="box-compliance">
                    <div class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg." + dataAccount.backOfficeStatus)'></div>

                    <!-- <div v-if='dataAccount.backOfficeStatus === "Pending" && !dataAccount.documentsValidated' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg.Pending")'></div>
                    <div v-if='dataAccount.backOfficeStatus === "Pending" && dataAccount.documentsValidated' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg.waitingAccept")'></div>
                    <div v-if='dataAccount.backOfficeStatus === "None"' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg.None")'></div>
                    <div v-if='dataAccount.backOfficeStatus === "Review"' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg.Review")'></div>
                    <div v-if='dataAccount.backOfficeStatus === "Accepted"' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg.Accepted")'></div>
                    <div v-if='dataAccount.backOfficeStatus === "Rejected"' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg.Rejected")'></div> -->

                    <!-- PERSONAL INFORMATION -->
                    <div class="box-registration" v-for="(listPartner, index) in dataAccount.partners" :key="index">
                        <div class="formBox">
                            <h3 class="subtitle">
                                {{getMyLanguage("compliance","compliance.company.title-partner")}}
                            </h3>

                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <label class="formFullName">
                                        {{getMyLanguage("compliance","compliance.lbl.fullName")}}
                                        <span class="form formDisabled">{{listPartner.fullName}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formYourEmail">
                                        {{getMyLanguage("compliance","compliance.lbl.email")}}
                                        <span class="form formDisabled">{{listPartner.email}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formPhone">
                                        {{getMyLanguage("compliance","compliance.lbl.phone")}}
                                        <span class="form formDisabled">{{listPartner.phoneNumber}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formResidenceCountry">
                                        {{getMyLanguage("compliance","compliance.lbl.country")}}
                                        <span class="form formDisabled" v-if="showCountry">
                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${dataAccount.countryFlag}/flags`" class="flag" v-if="dataAccount.countryFlag != false && dataAccount.countryFlag != null">
                                            {{ countryTranslate(dataCountry.filter(country => country.Name == listPartner.country)[0].IsoCode) }}
                                        </span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formPersonDOB">
                                        {{getMyLanguage("compliance","compliance.lbl.dob")}}
                                        <span class="form formDisabled">{{dateTime(listPartner.dob)}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formTaxNumber" :class="{required: errorsValidate.taxNumber}">
                                        {{getMyLanguage("compliance","compliance.lbl.taxNumber")}}
                                        <span class="form formDisabled">{{listPartner.taxNumber}}</span>
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label class="formStatusKYC" :class="{active: listPartner.kyc, pending: !listPartner.kyc}">
                                        {{getMyLanguage("compliance","compliance.lbl.kyc")}}
                                        
                                        <span class="form formDisabled statusKYC" v-if="listPartner.kyc">
                                            {{getMyLanguage("compliance","compliance.lbl.kyc.active")}}
                                        </span>

                                        <span class="form formDisabled statusKYC" v-if="!listPartner.kyc">
                                            {{getMyLanguage("compliance","compliance.lbl.kyc.pending")}}
                                        </span>
                                        
                                        <small v-if="!dataAccount.partnersValidated" v-html='getMyLanguage("compliance","compliance.msg.kyc.incomplete")'></small>
                                        <small v-if="dataAccount.partnersValidated" v-html='getMyLanguage("compliance","compliance.msg.kyc.complete")'></small>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- COMPANY DATA -->
                    <div class="box-registration address-compliance">
                        <div class="formBox">

                            <h3 class="subtitle">
                                {{getMyLanguage("compliance","compliance.company.title")}}
                            </h3>

                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <label class="noIcon">
                                        {{getMyLanguage("compliance","compliance.lbl.companyName")}}
                                        <input type="text" class="form" :class="{error: errorsValidate.fullName}" v-model="dataCompliance.fullName">
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label class="noIcon">
                                        {{getMyLanguage("compliance","compliance.lbl.email")}}
                                        <span class="form formDisabled">{{dataAccount.company.email}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="noIcon">
                                        {{getMyLanguage("compliance","compliance.lbl.country")}}
                                        <span class="form formDisabled" v-if="showCountry">{{ countryTranslate(dataCountry.filter(country => country.Name == dataAccount.company.countryName)[0].IsoCode) }}</span>
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label class="noIcon">
                                        {{getMyLanguage("compliance","compliance.lbl.phone")}}
                                        <span class="form formDisabled">{{dataAccount.company.phoneNumber}}</span>
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label class="noIcon">
                                        {{getMyLanguage("compliance","compliance.lbl.taxNumberCompany")}}
                                        <small>{{getMyLanguage("compliance","compliance.lbl.taxNumberCompanyCountry")}}</small>
                                        
                                        <input type="text" class="form" :class="{error: errorsValidate.taxNumber}" v-model="dataCompliance.taxNumber">
                                    </label>
                                </div>

                                <div class="col-12">
                                    <label class="observations-data noIcon">
                                        {{getMyLanguage("compliance","compliance.company.observations")}}
                                        <span class="cont">{{dataCompliance.observations.length}}/250</span>
                                        <textarea class="form" v-model="dataCompliance.observations" maxlength="250" :placeholder='getMyLanguage("compliance","compliance.placeholder.additional-address")'></textarea>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- DOCUMENTS -->
                    <div class="box-registration box-documents">
                        <div id="Docs"></div>
                        <div class="formBox">
                            <h3 class="subtitle">
                                {{getMyLanguage("compliance","compliance.company.title-docs")}}
                            </h3>

                            <p class="obs-mandatory">
                                {{getMyLanguage("compliance","compliance.company.obs-mandatory")}}
                            </p>

                            <div class="row formBox" v-if="!errorsGetDocs.dataAccount">
                                <div class="col-12 boxes-documents">
                                    <div
                                        v-for="(listDocumentType, indexDocs) in dataAccount.documents"
                                        :key="indexDocs"
                                        class="box-document"
                                        :class="{mandatory: listDocumentType.accountDocumentMandatory, [listDocumentType.accountDocumentStatusExtra]:true}"
                                        @click="openModalUploadDocument(listDocumentType.accountDocumentTypeCode, listDocumentType.accountDocumentStatusExtra)">

                                        <h3>
                                            {{ getMyLanguage("compliance","compliance.document-type." + listDocumentType.accountDocumentTypeCode) }}
                                        </h3>
                                        
                                        <p v-if="listDocumentType.accountDocumentMandatory && listDocumentType.accountDocumentStatusExtra == 'NotLoaded'">
                                            {{ getMyLanguage("compliance","compliance.document-status.NotLoaded.mandatory") }}
                                        </p>

                                        <p v-else>
                                            {{ getMyLanguage("compliance","compliance.document-status." + listDocumentType.accountDocumentStatusExtra) }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="box-error" v-else>
                                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                            </div>

                            <div class="message-docs" v-if="showPending || showSent">
                                <div v-if="showPending" class="box box-pending">
                                    <h3 class="title-secondary">{{ getMyLanguage("compliance","compliance.msg.title-Pending") }}</h3>
                                    <p>{{ getMyLanguage("compliance","compliance.msg.Pending") }}</p>
                                </div>

                                <div class="box box-success" v-else-if="showSent">
                                    <h3 class="title-secondary">{{ getMyLanguage("compliance","compliance.msg.title-waitingAccept") }}</h3>
                                    <p>{{ getMyLanguage("compliance","compliance.msg.Review") }}</p>
                                </div>
                            </div>

                            <div class="continue" v-if='!showPending'>
                                <a :href="`/${flagSelected}/account/ubo/company?id=${this.dataMerchantFilter.company_account_id}`" class="btn-primary">
                                    {{ getMyLanguage("kyc","kyc.shufti-accepted.btn") }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>


    <!-- MODAL DOCUMENT UPLOAD -->
    <div class="modal modal-document-upload" v-if="modalDocument.modal">
        <div class="modal-cont modalShort">
            <div class="modal-close" @click="modalDocument.modal = false, modalDocument.status = ''"></div>

            <div class="modal-scroll">
                <h2 class="title-secondary">
                    {{ getMyLanguage("compliance","compliance.document-type." + dataSendDoc.accountDocumentTypeCode) }}
                </h2>

                <div class="box-success" v-if="modalDocument.status == 'Accepted'">
                    <p>{{getMyLanguage("compliance","compliance.document-status.box-success")}}</p>
                </div>

                <div v-if="modalDocument.status != 'Accepted'">
                    <label for="LblUploadFile" class="upload">
                        <span v-if="!uploadFileDocument.selected">
                            {{getMyLanguage("compliance","compliance.document-status.btn-upload")}}
                            
                            <small>
                                ({{getMyLanguage("compliance","compliance.document-status.btn-upload.formats")}})
                            </small>
                        </span>

                        <em v-if="!uploadFileDocument.isImage" :class='uploadFileDocument.selected'>
                            <span class="send" v-if="!uploadFileDocument.selected">
                                {{getMyLanguage("compliance","compliance.document-status.msg.file-select")}}
                            </span>

                            <span class="selected" v-if="uploadFileDocument.selected">
                                {{getMyLanguage("compliance","compliance.document-status.msg.file-selected")}}
                            </span>
                        </em>

                        <input type="file" id="LblUploadFile" ref="file" @change="uploadDocument()">
                        
                        <div class="preview-image" v-if="uploadFileDocument.isImage">
                            <div>
                                <img :src="uploadFileDocument.previewURLImage" v-if="uploadFileDocument.previewURLImage" />
                            </div>
                        </div>
                    </label>

                    <div class="resume-file" v-if="uploadFileDocument.resumeFile">
                        <p>
                            <strong>
                                {{getMyLanguage("compliance","compliance.document-status.resume-file.file")}}
                            </strong>

                            {{ uploadFileDocument.File.name }}
                        </p>

                        <p>
                            <strong>
                                {{getMyLanguage("compliance","compliance.document-status.resume-file.type")}}
                            </strong>

                            {{ uploadFileDocument.File.type }}
                        </p>

                        <p>
                            <strong>
                                {{getMyLanguage("compliance","compliance.document-status.resume-file.size")}}
                            </strong>

                            {{ formatFileSize(uploadFileDocument.File ? uploadFileDocument.File.size : 0) }}
                        </p>
                    </div>

                    <div class="observations-upload noIcon">
                        <label>
                            <span>
                                {{getMyLanguage("compliance","compliance.document-status.upload-obs")}}
                            </span>

                            <span class="cont">{{dataSendDoc.Observations.length}}/200</span>
                        </label>

                        <textarea class="form" maxlength="200"
                        v-model="dataSendDoc.Observations"
                        :placeholder='getMyLanguage("compliance","compliance.document-status.upload-obs.placeholder")'></textarea>
                    </div>

                    <div class="box-error" v-if="errorsPostDocs.upload != false">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{getMyLanguage("compliance","compliance.document-status.upload-error")}}</p>
                    </div>

                    <div class="bt-upload">
                        <div class="btn-primary btn-disabled" v-if='uploadFileDocument.selected != "fileSelected" || dataSendDoc.accountDocumentTypeCode === ""'>
                            {{getMyLanguage("compliance","compliance.document-status.send-file")}}
                        </div>

                        <div class="btn-primary"
                        @click="uploadDoc()"
                        v-if='uploadFileDocument.selected == "fileSelected"
                            && !errorsPostDocs.upload
                            && !uploadFileDocument.loadUpload
                            && dataSendDoc.accountDocumentTypeCode != ""'>

                            {{getMyLanguage("compliance","compliance.document-status.send-file")}}
                        </div>
                        
                        <div class="btn-primary load" v-if="uploadFileDocument.loadUpload"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Header.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
    import countriesList from "@/services/listCountries.js";

    import apiInternational from '@/config/apiInternational.js'
    import apiMerchant from '@/config/apiMerchant.js'
    import moment from 'moment';

    import { handleError } from '@/services/errorHandler.js'
  
    export default {
        components: {
            Header
        },

        data(){
            return{
                showContent: false,
                showCountry: false,
                load: false,

                idCompany: "",
                accountId: "",

                modalDocument: {
                    modal: false,
                    status: "",
                },

                errorsGet: {
                    dataAccount: {
                        data: "",
                        code: "",
                    }
                },

                errorsPost: {
                    dataCompliance: false,
                    upload: false
                },

                flagSelected: "en",

                dataAccount: [],
                dataDocuments: [],

                showPending: false,
                showSent: false,

                uploadFileDocument: {
                    File: null,
                    selected: false,
                    documentType: "1",
                    loadUpload: false,
                    isImage: false,
                    resumeFile: false,
                    previewURLImage: ""
                },

                dataSendDoc: {
                    accountDocumentTypeCode: '',
                    Observations: ''
                },

                errorsGetDocs: {
                    dataCompliance: false
                },

                errorsPostDocs: {
                    upload: false
                },                

                hasPending: '',
                
                dataCountry: [],
                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryFlag: false,

                accept: {
                    terms: false,
                    notify: false,
                    gdpr: false
                },

                dataCompliance: {},
                dataMerchant: {},
                dataMerchantFilter: {},

                uploadFile: {
                    File: null,
                    selected: false,
                    documentType: "1",
                    loadUpload: false
                },

                modalNoConfirm: {
                    modal: false,
                    load: false,
                    send: false
                },

                noConfirm: {
                    error: "Address error",
                    msg: ""
                },

                errorsValidate: {
                    taxNumber: false,
                    taxNumberIssuerCountry: false
                }
            }
        },
        
        async mounted() {
            const urlParams = new URLSearchParams(window.location.search);
            this.idCompany = urlParams.get('id');

            if ( this.idCompany ){
                this.loadCompliance()
            }
            
            this.loadListStore()


            // if (this.idCompany) {
            //     urlParams.delete('id');
            //     const newUrl = window.location.pathname + (urlParams.toString() ? '?' + urlParams.toString() : '');
            //     window.location.replace(newUrl);
            // } else {
            //     this.loadCompliance();
            // }
        },



        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.account.compliance");
                document.head.querySelector('meta[name=description]').content = ''
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },


            // LOAD STORE LIST
            async loadListStore(){
                await apiMerchant.get('/api/v1/market/store/list')
                .then(response => {
                    this.dataMerchant = response.data
                    
                    this.dataMerchantFilter = this.dataMerchant.filter(store => store.company_id == this.idCompany)[0]

                    localStorage.setItem("storeReference", this.dataMerchantFilter.store.reference)
                    localStorage.setItem("storeName", this.dataMerchantFilter.store.name)
                    localStorage.setItem("companyReference", this.dataMerchantFilter.company_reference)
                    localStorage.setItem("companyAccountId", this.dataMerchantFilter.company_account_id)
                    localStorage.setItem("companyId", this.dataMerchantFilter.company_id)
                })
                .catch(error => {
                    console.log(error)

                    this.$toast.error(this.getMyLanguage('box-error','msg-error'))
                })
            },


            // LOAD COMPLIANCE
            async loadCompliance(){
                try {
                    const responseCompany = await apiInternational.get(`/api/v1/international/compliance/company/${this.idCompany}`)
                    this.dataAccount = responseCompany.data

                    this.dataCompliance.fullName = this.dataAccount.company.fullName
                    this.dataCompliance.taxNumber = this.dataAccount.company.taxNumber
                    this.dataCompliance.observations = this.dataAccount.company.observations

                    this.idCompany = localStorage.getItem("companyId")

                    setTimeout(() => {
                        this.showContent = true
                    }, 300);

                    // CHECK DOCUMENTS
                    setTimeout(() => {
                        this.checkDocuments()
                    }, 600);
                }
                catch (error) {
                    console.log(error)

                    if (error.response) {
                        this.errorsGet.dataAccount.data = error.response.data
                        this.errorsGet.dataAccount.code = error.response.status
                    } else {
                        this.errorsGet.dataAccount.code = "500";
                    }

                    this.showContent = true;
                    handleError(error, this.flagSelected);
                }
            },


            // COUNTRIES
            countryTranslate(code){
                return countriesList(code, this.$store.state.SelectLanguage.code)
            },

            ordercountries() {
                this.countriesTranslated = [...this.dataCountry];
                
                this.countriesTranslated.forEach(lang => {
                    lang.translatedCountry = this.countryTranslate(lang.IsoCode);
                });

                this.countriesTranslated.sort((a, b) => {
                    return a.translatedCountry.toLowerCase().localeCompare(b.translatedCountry.toLowerCase());
                });
            },


            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            validateForm(){
                if ( this.dataCompliance.fullName === null || this.dataCompliance.fullName === "" ){
                    this.errorsValidate.fullName = true
                } else{
                    this.errorsValidate.fullName = false
                }
                
                if ( this.dataCompliance.taxNumber === null || this.dataCompliance.taxNumber === "" ){
                    this.errorsValidate.taxNumber = true
                } else{
                    this.errorsValidate.taxNumber = false
                }
                
                if ( this.errorsValidate.fullName === false && this.errorsValidate.taxNumber === false ) {
                    this.register()
                }
            },

            register(){
                this.load = true
                const compliance = this.dataCompliance;

                apiInternational.post('/api/v1/international/compliance/company', compliance)
                .then(response => {
                    this.registerId = response.data.id;

                    this.$toast.success(this.getMyLanguage("compliance","compliance.toast.saveData"));
                    setTimeout(this.$toast.clear, 6000)

                    this.updateCompliance()

                    window.location.href = '#top'
                })
                .catch(error => {
                    this.errorsGet.dataAccount = error.response.status

                })
                
                setTimeout(() => {
                    this.load = false
                }, 500);
            },


            async updateCompliance(){
                const updateCompliance = await apiInternational.get('/api/v1/international/compliance/company')
                this.dataAccount = updateCompliance.data
            },

            checkDocuments() {
                const mandatoryDocs = this.dataAccount.documents.filter(
                    (doc) => doc.accountDocumentMandatory === true
                );

                const hasPending = mandatoryDocs.some(
                    (doc) =>
                        doc.accountDocumentStatusExtra !== "Accepted"
                );

                const allAcceptedOrLoaded = mandatoryDocs.every(
                    (doc) =>
                        doc.accountDocumentStatusExtra === "Accepted"
                );

                const hasLoaded = mandatoryDocs.some(
                    (doc) => doc.accountDocumentStatusExtra === "Loaded"
                );

                this.showPending = hasPending;
                this.showSent = !hasPending && allAcceptedOrLoaded && hasLoaded;
            },

            openModalUploadDocument(typeCode, status){
                this.dataSendDoc.accountDocumentTypeCode = typeCode
                this.modalDocument.modal = true
                this.modalDocument.status = status
            },

            uploadDocument() {
                this.uploadFileDocument.File = this.$refs.file.files[0];
                
                if ( this.uploadFileDocument.File != null ){
                    this.uploadFileDocument.selected = "fileSelected"
                    
                    const fileURL = URL.createObjectURL(this.uploadFileDocument.File);
                    this.uploadFileDocument.previewURLImage = fileURL;
                    this.uploadFileDocument.resumeFile = true
                    
                    setTimeout(() => {
                        URL.revokeObjectURL(fileURL);
                    }, 10000);
                }

                if (this.uploadFileDocument.File.type.startsWith('image/')) {
                    this.uploadFileDocument.isImage = true
                } else {
                    this.uploadFileDocument.isImage = false
                }
            },

            formatFileSize(fileSize) {
                if (fileSize === 0) return '0 Bytes';
                const k = 1024;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                const i = Math.floor(Math.log(fileSize) / Math.log(k));
                return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
            },

            uploadDoc(){
                this.uploadFileDocument.loadUpload = true
                const formData = new FormData();
                formData.append('File', this.uploadFileDocument.File);
                formData.append('Type', this.dataSendDoc.accountDocumentTypeCode);
                formData.append('Observations', this.dataSendDoc.Observations);
                const token = localStorage.getItem('accessToken');
                const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}` };
                
                apiInternational.post(`/api/v1/international/compliance/company/${this.idCompany}/document`, formData, { headers })
                .then(response => {
                    console.log(response)
                    console.clear()
                    
                    location.reload()
                })
                .catch(error => {
                    console.log(error)
                    this.errorsPostDocs.upload = "error upload"

                    setTimeout(() => {
                        this.errorsPostDocs.upload = false
                        this.uploadFileDocument.loadUpload = false
                    }, 10000);
                })
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                    this.ordercountries()
                }
            },

            'this.$store.state.allCountries': {
                immediate: true,
                handler() {
                    setTimeout(() => {
                        this.dataCountry = this.$store.state.allCountries
                        this.showCountry = true
                    }, 1500);
                }
            },

            "$store.state.profile": {
                handler() {
                    setTimeout(() => {
                        if ( !this.idCompany ){
                            this.loadCompliance();
                        }
                    }, 1000);
                }
            },
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.compliance");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-compliance.scss" lang="scss" scoped />