<template>
    <div class="home">
        <div id="dne"></div>
        <Header />

        <div id="clientSpace">

            <section class="client-space client-space-home">
                <div class="anc" id="client-space"></div>
                <div class="container">
                    <ClientSpaceResume />
                </div>
            </section>

            <!-- PURCHASE HISTORY -->
            <div class="anc" id="purchase-history"></div>
            <PurchaseHistory />

            <!-- INCREASE YOUR TOKENS -->
            <section class="increase">
                <div class="container">
                    <h2 class="title-secondary">
                        {{ getMyLanguage("client-space", "client-space.increase-title") }}
                    </h2>

                    <!-- <p class="subtitle-secondary">{{ getMyLanguage("client-space", "client-space.increase-subtitle.icoPrivate") }}</p> -->
                    <p class="subtitle-secondary">
                        {{ getMyLanguage("client-space", "client-space.increase-subtitle") }}
                    </p>

                    <div class="buy-dne-token">
                        <a :href="`/${flagSelected}/client-space/purchase`" class="btn-primary">
                            <span>{{ getMyLanguage("client-space", "client-space.increase-cta") }}</span>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import ClientSpaceResume from '@/components/ClientSpaceResume.vue';
    import PurchaseHistory from '@/components/PurchaseHistory.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
  
    export default {
        components: {
            Header,
            ClientSpaceResume,
            PurchaseHistory
        },

        beforeMount(){
            this.loginStatus()
        },

        data() {
            return {
                flagSelected: "en"
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.client-space");
                document.head.querySelector('meta[name=description]').content = ''
            },

            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                    window.location.href = '/'
                }
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.title = this.getMyLanguage("seo","title.client-space");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        }
    }
</script>

<style src="./custom-clientSpace.scss" lang="scss" scoped />