<template>
    <section class="list-history">
        <div class="load" v-if="load"></div>

        <!-- <div class="container">
            <div class="box-error">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error","block-purchase") }}</p>
            </div>
        </div> -->

        <div class="container" v-if='!load'>
            <h2 class="title-secondary">
                {{getMyLanguage("client-space-invites", "invites.title")}}
            </h2>
            
            <div class="row">
                <div class="col-12">
                    <div class="list-history-count">
                        <table class="list-history-table">
                            <thead class="list-history-header">
                                <tr>
                                    <th class="name">{{ getMyLanguage("client-space","client-space.invites-history-table-title-name-invited") }}</th>
                                    <th class="name">{{ getMyLanguage("client-space","client-space.invites-history-table-title-name-agent") }}</th>
                                    <th>{{ getMyLanguage("client-space","client-space.invites-history-table-title-type-invite") }}</th>
                                    <th>{{ getMyLanguage("client-space","client-space.invites-history-table-title-created-at") }}</th>
                                    <th>{{ getMyLanguage("client-space","client-space.invites-history-table-title-expires-at") }}</th>
                                    <th>{{ getMyLanguage("client-space","client-space.invites-history-table-title-status") }}</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr class="list-history-list" v-for="(itemHistory, index) in dataHistory" :key="index">
                                    <td class="name" v-if='itemHistory.status != "Generated"'>
                                        <small>{{itemHistory.account_profile.full_name}}</small>
                                    </td>

                                    <td class="name name-temp" v-if='itemHistory.status === "Generated"'>
                                        <small>{{itemHistory.description}}</small>
                                    </td>
                                    
                                    <td class="name">
                                        <small>{{itemHistory.agent.name}}</small>
                                    </td>

                                    <td><small>{{ getMyLanguage("client-space-status", itemHistory.type_code) }}</small></td>

                                    <td><small>{{ dateTime(itemHistory.created_at) }}</small></td>
                                    <td><small>{{ dateTime(itemHistory.expires_at) }}</small></td>

                                    <td class="status tooltip" :class="itemHistory.status">
                                        <span><small>{{ getMyLanguage("client-space-status", itemHistory.status) }}</small></span>
                                    
                                        <div class="tooltip">
                                            {{ getMyLanguage("client-space-invites","tooltip." + itemHistory.status) }}
                                        </div>
                                    </td>

                                    <td>
                                        <div class="lnk-invite active" v-if="itemHistory.status == 'Generated'" @click="copyShare(itemHistory.id)"></div>
                                        <div class="lnk-invite" v-if="itemHistory.status != 'Generated'"><small>--</small></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row" v-if="!load">
                <div class="col-12 col-lg-6">
                    <div class="box-cta cta-invite">
                        <h2>{{ getMyLanguage("client-space-invites","generate-invite.title") }}</h2>
                        <p>{{ getMyLanguage("client-space-invites","generate-invite.text") }}</p>

                        <div class="access">
                            <div class="btn-primary" @click="generate()">
                                {{getMyLanguage("client-space","client-space.bonus-invite-cta")}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="box-cta cta-sales">
                        <h2><span>DNE</span> Sales</h2>

                        <p>
                            <strong>{{ getMyLanguage("client-space-invites","dne-sales.txt") }}</strong>
                        </p>

                        <div class="access">
                            <a :href="`${VUE_APP_SALES_URL}${flagSelected}/login`" target="_blank" class="btn-primary">
                                {{ getMyLanguage("client-space-invites","dne-sales.btn") }}
                            </a>

                            <p>
                                <small>{{ getMyLanguage("client-space-invites","dne-sales.instruction") }}</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import apiInternational from '@/config/apiInternational.js';
    import { handleError } from '@/services/errorHandler.js'
    
    import moment from "moment";

    export default {
        data() {
            return {
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                VUE_APP_SALES_URL: process.env.VUE_APP_SALES_URL,
                flagSelected: "en",
                load: true,
                dataHistory: [],

                errorGet: false
            };
        },

        async mounted() {
            await apiInternational.get("/api/v2/sales/agent/invites")
            .then((response) => {
                this.dataHistory = response.data;

                setTimeout(() => {
                    this.load = false
                }, 1000);
            })
            .catch((error) => {
                setTimeout(() => {
                    this.load = false
                }, 1000);

                handleError(error, this.flagSelected);
            });
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
                return moment(value).format(format);
            },

            generate(){
                var btnGenerate = document.querySelector('#generateInvite');
                btnGenerate.click();
            },

            async copyShare(idInvite) {
                try {
                await navigator.clipboard.writeText(`${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/invite/${idInvite}`);
                    this.openToastCopy()
                }
                
                catch($e) {
                    this.openToastNoCopy()
                }
            },

            async openToastCopy() {
                this.$toast.success(this.getMyLanguage("client-space","instructions.copied"));
                setTimeout(this.$toast.clear, 6000)
            },            
            
            async openToastNocopy() {
                this.$toast.success(this.getMyLanguage("client-space","instructions.nocopied"));
                setTimeout(this.$toast.clear, 6000)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    };
</script>

<style src="@/assets/scss/_list-history.scss" lang="scss" scoped />