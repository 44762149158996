<template>
    <div class="home">
        <div id="dne"></div>
        <Header />

        <div id="clientSpace">
            <section class="client-space">
                <div class="anc" id="client-space"></div>
                <div class="container">
                    <ClientSpaceResume />
                </div>
            </section>

            <div id="anchor-content"></div>

            <div class="box-error purchase-cancelled" v-html='getMyLanguage("client-space","purchase-cancelled.txt")' v-if='dataPurchaseSepa.phasePurchaseStatus === "Cancelled"'></div>


            <!-- SWAPIX -->
            <section class="success swapix" v-if='dataPurchaseSepa.paymentType === "PIX" && dataPurchaseSepa.phasePurchaseStatus != "Cancelled"'>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-10 col-lg-10">
                            <h2 class="title-secondary">
                                {{ getMyLanguage("client-space", "client-space.success.title") }}
                            </h2>

                            <div class="success-msg">
                                <div class="header-box">
                                    <p>
                                        <strong>{{ getMyLanguage("client-space", "summary.pendingPayment.quantity") }}: </strong>
                                        {{dataPurchaseSepa.quantity}} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}
                                    </p>

                                    <p>
                                        <strong>{{ getMyLanguage("client-space","instructions.value") }}: </strong>
                                        <span>{{(dataPurchaseSepa.finalPrice * dataPurchaseSepa.finalPriceAliquot).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} </span>&nbsp;
                                        <small>/ {{dataPurchaseSepa.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</small>
                                    </p>
                                </div>

                                <div v-html='getMyLanguage("client-space","client-space.success.swapix-txt")'></div>

                                <div class="qrcode-pix">
                                    <qrcode-vue :value="qrCode.value" :size="qrCode.size" level="L" />
                                </div>
                                
                                <div class="list-iban">
                                    <p><small>{{qrCode.value}}</small><span class="copy" @click='copyInstruction(qrCode.value)'></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <!-- SWIFT/SEPA -->
            <div v-if='dataPurchaseSepa.paymentType === "SEPA/SWIFT" && dataPurchaseSepa.phasePurchaseStatus != "Cancelled"'>

                <section class="success">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-10 col-lg-10">
                                <h2 class="title-secondary">
                                    {{ getMyLanguage("client-space", "client-space.success.title") }}
                                </h2>

                                <div class="success-msg" v-html='getMyLanguage("client-space","client-space.success.sepa-txt")'></div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="sections instructions-sepa">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-10 col-lg-10">
                                <div class="content-instructions-sepa" v-if="showContent">
                                    <h3 class="title-secondary">
                                        {{ getMyLanguage("client-space","instructions.title") }}
                                    </h3>

                                    <div class="header-box">
                                        <!-- <p><strong>{{ getMyLanguage("client-space","instructions.nameOrigin") }}: </strong>{{dataPurchaseSepa.personName}}</p> -->
                                        <p>
                                            <strong>{{ getMyLanguage("client-space", "summary.pendingPayment.quantity") }}: </strong>
                                            {{dataPurchaseSepa.quantity}} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}
                                        </p>

                                        <p>
                                            <strong>{{ getMyLanguage("client-space","instructions.value") }}: </strong>
                                            {{dataPurchaseSepa.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}
                                        </p>

                                        <p class="reference">
                                            <strong>{{ getMyLanguage("client-space","instructions.reference") }}: </strong>
                                            {{dataPurchaseSepa.reference}}&nbsp;
                                            <small>({{getMyLanguage("client-space","instructions.reference.msg")}})</small>
                                        </p>
                                    </div>
                                    
                                    <div class="list-iban" v-for="(listIban, index) in dataPurchaseSepa.ibans" :key="index">
                                        <p>
                                            {{listIban.destination}}&nbsp;
                                            <span class="copy" @click="copyInstruction(listIban.destination)"></span>
                                        </p>

                                        <p class="iban">
                                            <strong>{{ getMyLanguage("client-space","instructions.iban") }}: </strong>
                                            {{listIban.iban}}&nbsp;
                                            <span class="copy" @click="copyInstruction(listIban.iban)"></span>
                                        </p>

                                        <p>
                                            <strong>{{ getMyLanguage("client-space","instructions.swift") }}: </strong>
                                            {{listIban.swiftCode}}&nbsp;
                                            <span class="copy" @click="copyInstruction(listIban.swiftCode)"></span>
                                        </p>

                                        <!-- <p><strong>{{ getMyLanguage("client-space","instructions.value") }}: </strong>{{dataPurchaseSepa.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}} <span class="copy" @click='copyInstruction(dataPurchaseSepa.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"}))'></span></p> -->
                                        
                                        <p class="reference">
                                            <strong>{{ getMyLanguage("client-space","instructions.reference") }}: </strong>
                                            {{listIban.reference}}
                                            <span class="copy" @click="copyInstruction(listIban.reference)"></span>
                                        </p>

                                        <p class="obs">
                                            *** {{getMyLanguage("client-space","instructions.reference.msg")}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="iban">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-10">
                                <div class="msg-iban" v-html='getMyLanguage("client-space","sepa_swift.text")'></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>



            <!-- CRYPTO -->
            <div v-if='dataPurchaseSepa.paymentType === "Crypto" && dataPurchaseSepa.phasePurchaseStatus != "Cancelled"'>
                <section class="success">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-10 col-lg-10">
                                <h2 class="title-secondary">
                                    {{ getMyLanguage("client-space", "client-space.success.title") }}
                                </h2>

                                <div class="success-msg" v-html='getMyLanguage("client-space","client-space.success.crypto-txt")'></div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="sections instructions-sepa">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-10 col-lg-10">
                                <div class="content-instructions-sepa" v-if="showContent">
                                    <h3 class="title-secondary">
                                        {{ getMyLanguage("client-space","instructions.title") }}
                                    </h3>

                                    <div class="header-box">
                                        <!-- <p><strong>{{ getMyLanguage("client-space","instructions.nameOrigin") }}: </strong>{{dataPurchaseSepa.personName}}</p> -->
                                        <p>
                                            <strong>{{ getMyLanguage("client-space", "summary.pendingPayment.quantity") }}: </strong>
                                            {{dataPurchaseSepa.quantity}} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}
                                        </p>

                                        <p>
                                            <strong>{{ getMyLanguage("client-space","instructions.value") }}: </strong>
                                            <span class="value-crypto">
                                                {{(dataPurchaseSepa.finalPrice * dataPurchaseSepa.finalPriceAliquot).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT
                                            </span>&nbsp;
                                            <small>/ ({{dataPurchaseSepa.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}})</small>
                                        </p>
                                    </div>

                                    <div class="list-iban" v-for="(listCrypto, index) in dataPurchaseSepa.addresses" :key="index">
                                        <p>
                                            <strong>{{listCrypto.network}}</strong>
                                        </p>

                                        <p>
                                            {{listCrypto.address}}
                                            <span class="copy" @click="copyInstruction(listCrypto.address)"></span>
                                        </p>

                                        <p>
                                            <strong>{{ getMyLanguage("client-space","instructions.value") }}: </strong>
                                            <span class="value-crypto">{{(dataPurchaseSepa.finalPrice * dataPurchaseSepa.finalPriceAliquot).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT</span>&nbsp;
                                            <small>/ ({{dataPurchaseSepa.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}})</small>&nbsp;
                                            <span class="copy" @click='copyInstruction((dataPurchaseSepa.finalPrice * dataPurchaseSepa.finalPriceAliquot).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"}))'></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="iban">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-10">
                                <div class="msg-iban" v-html='getMyLanguage("client-space","crypto.text")'></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <!-- PURCHASE HISTORY -->
            <PurchaseHistory />


            <!-- INCREASE YOUR TOKENS -->
            <section class="increase">
                <div class="container">
                    <h2 class="title-secondary">
                        {{ getMyLanguage("client-space", "client-space.increase-title") }}
                    </h2>

                    <!-- <p class="subtitle-secondary">{{ getMyLanguage("client-space", "client-space.increase-subtitle.icoPrivate") }}</p> -->
                    
                    <p class="subtitle-secondary">
                        {{ getMyLanguage("client-space", "client-space.increase-subtitle") }}
                    </p>

                    <div class="buy-dne-token">
                        <a :href="`/${flagSelected}/client-space/purchase`" class="btn-primary">
                            <span>{{ getMyLanguage("client-space", "client-space.increase-cta") }}</span>
                        </a>
                    </div>
                </div>
            </section>

        </div>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import ClientSpaceResume from '@/components/ClientSpaceResume.vue';
    import PurchaseHistory from '@/components/PurchaseHistory.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import QrcodeVue from 'qrcode.vue'

    import moment from 'moment';

    import apiInternational from '@/config/apiInternational.js'
  
    export default {
        components: {
            Header,
            ClientSpaceResume,
            PurchaseHistory,
            QrcodeVue
        },

        beforeMount(){
            this.loginStatus()
        },

        data(){
            return{
                flagSelected: "en",
                
                qrCode:{
                    value: "",
                    size: 220
                },

                dataPurchaseSepa: [],
                showContent: false
            }
        },
        
        async mounted(){
            const lastPurchaseSepa = await apiInternational.get(`/api/v1/ico/token_sale/purchases/${this.$route.params.registerId}`)
            this.dataPurchaseSepa = lastPurchaseSepa.data

            if ( this.dataPurchaseSepa.pixPayload != null ){
                this.qrCode.value = this.dataPurchaseSepa.pixPayload
            }

            this.showContent = true
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.client-space.successSepa");
                document.head.querySelector('meta[name=description]').content = ''
            },

            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                    window.location.href = '/'
                }
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            goTop(){
                window.location.hash="anchor-content";
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
                return moment(value).format(format);
            },

            async copyInstruction(instruction) {
                try {
                await navigator.clipboard.writeText(instruction);
                    this.$toast.success(this.getMyLanguage("client-space","instructions.copied"));
                    setTimeout(this.$toast.clear, 3000)
                }
                
                catch($e) {
                    this.$toast.success(this.getMyLanguage("client-space","instructions.nocopied"));
                    setTimeout(this.$toast.clear, 3000)
                }
            },

            copyError(){
                this.$toast.error(this.getMyLanguage("client-space","instructions.nocopied"));
                setTimeout(this.$toast.clear, 3000)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.title = this.getMyLanguage("seo","title.client-space.successSepa");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-clientSpace.scss" lang="scss" scoped />
<style src="./custom-clientSpaceSuccess.scss" lang="scss" scoped />

<style scoped>
    .success{margin-bottom: 0;}
</style>