<template>
    <section class="list-history" :class="{blockPurchase: blockPurchase}">
        <div class="load" v-if="load"></div>

        <div class="container" v-if="blockPurchase">
            <div class="box-error" v-if="blockPurchase">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error","block-purchase") }}</p>
            </div>
        </div>

        <div class="container" v-if="!blockPurchase && dataHistory.length && !load">
            <h2 class="title-secondary">{{getMyLanguage("client-space", "client-space.purchase-history-title")}}</h2>
            
            <div class="row">
                <div class="col-12">
                    <div class="list-history-count">
                        <table class="list-history-table">
                            <thead class="list-history-header">
                                <tr>
                                    <th>{{getMyLanguage("client-space","client-space.purchase-history-table-title-date")}}</th>
                                    <th>{{getMyLanguage("client-space","client-space.purchase-history-table-title-phase")}}</th>
                                    <th>{{getMyLanguage("client-space","client-space.purchase-history-table-title-payment")}}</th>
                                    <th>{{getMyLanguage("client-space","client-space.purchase-history-table-title-status")}}</th>
                                    <th>{{getMyLanguage("client-space","client-space.purchase-history-table-title-quantity")}}</th>
                                    <th>{{getMyLanguage("client-space","client-space.purchase-history-table-title-price")}}</th>
                                    <th>{{getMyLanguage("client-space","client-space.purchase-history-table-title-agreement")}}</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr class="list-history-list" v-for="(itemHistory, index) in dataHistory" :key="index">
                                    <td>{{ dateTime(itemHistory.createdAt) }}</td>
                                    <td>{{ itemHistory.phaseName }}</td>
                                    <td>{{ getMyLanguage("client-space", itemHistory.paymentType) }}</td>
                                    <td class="status-payment" :class="itemHistory.phasePurchaseStatus">
                                        {{getMyLanguage("client-space",itemHistory.phasePurchaseStatus)}}
                                        <div class="changePay" @click="changePayment(index)"></div>
                                    </td>
                                    <td>
                                        {{ itemHistory.quantity }}
                                        <small>{{getMyLanguage("client-space","client-space.purchase-history-table-title-packages")}}</small>
                                    </td>
                                    <td>{{itemHistory.finalPrice.toLocaleString("pt-PT", {style: "currency",currency: "EUR",})}}</td>
                                    <td>
                                        <div class="invoice">
                                            <!-- <div class="lnk-invoice" @click='purchaseAgreement(itemHistory.agreementId, itemHistory.agreementDocument, "http://bafybeieig52rn42s5apsfstwyopqhelnheoo34e65fkxbdzgj2zfsrlrfy.ipfs.localhost:8080/")' v-if='itemHistory.agreementId != null'></div> -->
                                            <div class="lnk-invoice" @click='purchaseAgreement(itemHistory.agreementId, itemHistory.agreementDocument)' v-if='itemHistory.agreementId != null'></div>
                                            <div v-if='itemHistory.agreementId === null'>--</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <!-- MODAL AGREEMENT -->
        <div class="modal modal-invoice" v-if="modalAgreement.status">
            <div class="modal-cont">
                <div class="modal-close" @click="modalAgreement.status = false"></div>
                
                <h2 class="title-secondary">{{getMyLanguage("client-space","modal-agreement.title-registered")}}</h2>
                <a :href="modalAgreement.lnkBlockChain" target="_blank" class="lnk_blockchain"><small>{{modalAgreement.idBlockChain}}</small></a>
                 
                <iframe :src="urlAgreement" frameborder="0"></iframe>
            </div>
        </div>


        <!-- MODAL PENDING PAYMENT -->
        <div class="modal modal-payment" v-if="pendingPay.modal">
            <div class="modal-cont">
                <div class="modal-scroll">
                    <div class="modal-close" @click='pendingPay.modal = false, uploadReceipt.File = null, uploadReceipt.resumeFile = false, pendingPay.reviewSuccess = false, validation.Hash = true, cancelingPurchase = false'></div>
                    
                    <div class="row row-payment">
                        <div class="payment-content payment-pending" v-if='pendingPay.phasePurchaseStatus === "PendingPayment" && !cancelingPurchase'>
                            <h2 class="title-secondary">{{getMyLanguage("client-space","activity.pending.payment.title")}}</h2>
                            <h6 class="payUp">{{getMyLanguage("client-space","activity.pending.payment.payUp")}} {{ dateTime(pendingPay.payUpTo) }}</h6>
                            
                            <!-- BOX SEPA/SWIFT -->
                            <div class="box" v-if='pendingPay.paymentType === "SEPA/SWIFT"'>
                                <div class="header-box">
                                    <p><strong>{{getMyLanguage("client-space","summary.pendingPayment.quantity")}}: </strong>{{ pendingPay.quantity }} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}</p>
                                    <p><strong>{{getMyLanguage("client-space", "instructions.value")}}: </strong>{{pendingPay.finalPrice.toLocaleString("pt-PT", {style: "currency",currency: "EUR",})}}</p>
                                    <p class="reference"><strong>{{getMyLanguage("client-space", "instructions.reference")}}: </strong>{{ pendingPay.reference }}</p>
                                    <!-- <p class="obs"><small> ({{getMyLanguage("client-space","instructions.reference.msg")}})</small></p> -->
                                </div>

                                <div class="list-iban" v-for="(listIban, index) in pendingPay.ibans" :key="index">
                                    <p>{{ listIban.destination }}<span class="copy" @click="copyInstruction(listIban.destination)"></span></p>
                                    <p class="iban"><strong>{{getMyLanguage("client-space", "instructions.iban")}}: </strong>{{ listIban.iban }}<span class="copy" @click="copyInstruction(listIban.iban)"></span></p>
                                    <p><strong>{{getMyLanguage("client-space", "instructions.swift")}}: </strong>{{ listIban.swiftCode }}<span class="copy" @click="copyInstruction(listIban.swiftCode)"></span></p>
                                    <p class="reference"><strong>{{getMyLanguage("client-space", "instructions.reference")}}: </strong>{{ listIban.reference }}<span class="copy" @click="copyInstruction(listIban.reference)"></span></p>
                                    <p class="obs"><small>*** {{getMyLanguage("client-space","instructions.reference.msg")}}</small></p>
                                </div>
                            </div>


                            <!-- BOX CREDIT CARD -->
                            <div class="box box-credit-card" v-if='pendingPay.paymentType === "Credit Card"' v-html='getMyLanguage("client-space","instructions.credit-card.pending")'>
                            </div>

                            
                            <!-- BOX CRYPTO -->
                            <div class="box" v-if='pendingPay.paymentType === "Crypto"'>
                                <div class="header-box">
                                    <p><strong>{{getMyLanguage("client-space","summary.pendingPayment.quantity")}}: </strong>{{ pendingPay.quantity }} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}</p>
                                    <p><strong>{{getMyLanguage("client-space", "instructions.value")}}: </strong><span class="value-crypto">{{(pendingPay.finalPrice * pendingPay.finalPriceAliquot).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT</span> <small>/ {{pendingPay.finalPrice.toLocaleString("pt-PT", {style: "currency",currency: "EUR",})}}</small></p>
                                </div>

                                <div class="list-iban" v-for="(listCrypto, index) in pendingPay.addresses" :key="index">
                                    <p><strong>{{listCrypto.network}}</strong></p>
                                    <p>{{listCrypto.address}}<span class="copy" @click="copyInstruction(listCrypto.address)"></span></p>
                                </div>
                            </div>
                            
                            
                            <!-- BOX SWAPIX -->
                            <div class="box box-swapix" v-if='pendingPay.paymentType === "PIX"'>
                                <div class="header-box">
                                    <p><strong>{{getMyLanguage("client-space","summary.pendingPayment.quantity")}}: </strong>{{ pendingPay.quantity }} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}</p>
                                    <p><strong>{{getMyLanguage("client-space", "instructions.value")}}: </strong><span class="value-crypto">{{(pendingPay.finalPrice * pendingPay.finalPriceAliquot).toLocaleString("pt-BR", {style: "currency",currency: "BRL",})}} </span> <small>/ {{pendingPay.finalPrice.toLocaleString("pt-PT", {style: "currency",currency: "EUR",})}}</small></p>
                                </div>

                                <div class="swapix-pending">
                                    <div class="qrcode-pix">
                                        <qrcode-vue :value="qrCode.value" :size="qrCode.size" level="L" />
                                    </div>
                                    
                                    <div class="list-iban">
                                        <p><small>{{qrCode.value}}</small><span class="copy" @click='copyInstruction(qrCode.value)'></span></p>
                                    </div>
                                </div>
                            </div>

                            
                            <!-- SEND RECEIPT / REVIEW -->
                            <div class="send-receipt" :class="{hasReview: pendingPay.reviews.length}" v-if='pendingPay.paymentType != "PIX" && pendingPay.paymentType != "Credit Card"'>
                                <div>
                                    <!-- UPLOAD -->
                                    <p v-if='pendingPay.paymentType === "SEPA/SWIFT"'><strong>{{getMyLanguage("client-space","instructions.receipt.txt")}}</strong></p>

                                    <div class="preview-file" v-if="uploadReceipt.resumeFile">
                                        <div class="preview-image" v-if="uploadReceipt.isImage">
                                            <div>
                                                <img :src="uploadReceipt.previewURLImage" v-if="uploadReceipt.previewURLImage" />
                                            </div>
                                        </div>

                                        <div class="resume-file">
                                            <p><strong>{{getMyLanguage("compliance","compliance.document-status.resume-file.file")}}</strong>&nbsp;{{ uploadReceipt.File.name }}</p>
                                            <p><strong>{{getMyLanguage("compliance","compliance.document-status.resume-file.type")}}</strong>&nbsp;{{ uploadReceipt.File.type }}</p>
                                            <p><strong>{{getMyLanguage("compliance","compliance.document-status.resume-file.size")}}</strong>&nbsp;{{ formatFileSize(uploadReceipt.File ? uploadReceipt.File.size : 0) }}</p>
                                        </div>
                                    </div>
                                    
                                    <label for="LblUploadReceipt" class="btn-upload" :class="{fileSelected: uploadReceipt.File != null, error: validation.File === false}" v-if='pendingPay.paymentType === "SEPA/SWIFT"'>
                                        <span class="send">{{getMyLanguage("client-space","instructions.receipt.upload.select-file")}}</span>
                                        <span class="selected">{{getMyLanguage("client-space","instructions.receipt.upload.selected-file")}}</span>

                                        <input type="file" id="LblUploadReceipt" ref="fileReceipt" @change="uploadFileReceipt()">
                                    </label>


                                    <!-- HASH -->
                                    <label v-if='pendingPay.paymentType === "Crypto"'>
                                        <strong>{{getMyLanguage("client-space","instructions.receipt.hash-crypto")}}</strong>
                                        <input type="text" class="form" :class="{error: validation.Hash === false}" placeholder="Hash" v-model="review.Receipt">
                                        <div class="empty-error" v-if="validation.Hash === false">{{getMyLanguage("client-space","instructions.receipt.error.hash")}}</div>
                                    </label>
                                    
                                    <label>
                                        <strong>{{getMyLanguage("client-space","instructions.receipt.observations")}}</strong>
                                        <textarea class="form" :class="{error: validation.Observations === false}" v-model="review.Observations"></textarea>
                                        <div class="empty-error" v-if="validation.Observations === false">{{getMyLanguage("client-space","instructions.receipt.error.observations")}}</div>
                                    </label>

                                    <div class="btn-primary" v-if="!pendingPay.load" @click="validateFormReview()">{{getMyLanguage("client-space","instructions.receipt.send")}}</div>
                                    <div class="btn-primary load" v-if="pendingPay.load"></div>
                                    <p v-if="pendingPay.reviewSuccess" class="success">{{getMyLanguage("client-space","instructions.receipt.upload.success.txt")}}</p>
                                </div>


                                <!-- ERROR UPLOAD -->
                                <div class="box-error" v-if="errorsPut.upload != false">
                                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                    <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                                    <div class="cod-error">
                                        Cód.: {{errorsPut.upload}}
                                    </div>
                                </div>


                                <!-- ERROR HASH -->
                                <div class="box-error" v-if="errorsPost.hash != false">
                                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                    <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                                    <div class="cod-error">
                                        Cód.: {{errorsPost.hash}}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- PAYMENT CANCELLED -->
                        <div class="payment-content payment-cancelled" v-if="pendingPay.phasePurchaseStatus === 'Cancelled'" :class="{hasReview: pendingPay.reviews.length}">
                            <h2 class="title-secondary">{{getMyLanguage("client-space","activity.pending.payment.title.cancelled")}}</h2>
                            <div class="box">
                                <p><strong>{{getMyLanguage("client-space", "instructions.reference")}}: </strong>{{ pendingPay.reference }}</p>
                                <p><strong>{{getMyLanguage("client-space","summary.pendingPayment.quantity")}}: </strong>{{ pendingPay.quantity }} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}</p>
                                <p><strong>{{getMyLanguage("client-space", "instructions.value")}}: </strong>{{pendingPay.finalPrice.toLocaleString("pt-PT", {style: "currency",currency: "EUR",})}}</p>
                                <p>&nbsp;</p>
                                <p><strong>{{getMyLanguage("client-space", "instructions.date")}}: </strong>{{ dateTime(pendingPay.updatedAt) }}</p>
                                <p><strong>{{getMyLanguage("client-space", "instructions.reason")}}: </strong>{{ pendingPay.phasePurchaseStatusReason }}</p>
                            </div>
                        </div>
                        
                        
                        <!-- PAYMENT PAID -->
                        <div class="payment-content payment-paid" v-if="pendingPay.phasePurchaseStatus === 'Paid'" :class="{hasReview: pendingPay.reviews.length}">
                            <h2 class="title-secondary">{{getMyLanguage("client-space","activity.paid.payment.title")}}</h2>
                            <div class="box">
                                <p><strong>{{getMyLanguage("client-space", "instructions.reference")}}: </strong>{{ pendingPay.reference }}</p>
                                <p><strong>{{getMyLanguage("client-space","summary.pendingPayment.quantity")}}: </strong>{{ pendingPay.quantity }} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}</p>
                                <p><strong>{{getMyLanguage("client-space", "instructions.value")}}: </strong>{{pendingPay.finalPrice.toLocaleString("pt-PT", {style: "currency",currency: "EUR",})}}</p>
                                <p>&nbsp;</p>
                                <p><strong>{{getMyLanguage("client-space", "instructions.date")}}: </strong>{{ dateTime(pendingPay.updatedAt) }}</p>
                                <p><strong>{{getMyLanguage("client-space", "instructions.reason")}}: </strong>{{ pendingPay.phasePurchaseStatusReason }}</p>
                            </div>
                        </div>


                        <!-- PAYMENT CANCELLED EXPIRED DATE -->
                        <div class="payment-content payment-cancelled expired-date" v-if="pendingPay.expired">
                            <h2 class="title-secondary">{{getMyLanguage("client-space","activity.pending.payment.title.cancelled")}}</h2>
                            <div class="box">
                                <p><strong>{{getMyLanguage("client-space","payment-cancelled.expired-date")}}</strong></p>
                            </div>
                        </div>


                        <!-- HISTORY -->
                        <div class="history" v-if="pendingPay.reviews.length">
                            <h2 class="title-secondary">{{getMyLanguage("history","modal-history.title")}}</h2>

                            <div class="box-history" v-for="(listHistory,index) in pendingPay.reviews" :key="index">
                                <div class="scroll">
                                    <p class="data-review">
                                        <span class="date">{{dateTime(listHistory.createdAt)}} ({{listHistory.accountName}})</span>
                                        <span class="status send">{{listHistory.reviewType}}</span>
                                    </p>
                                    <p>
                                        <span class="lnk" v-if='listHistory.attachment != "" && listHistory.attachmentType === "hash_tx"'><a :href="`https://blockscan.com/address/${pendingPay.addresses[0].address}`" target="_blank">{{listHistory.attachment}}</a></span>
                                        <!-- <span class="lnk" v-if='listHistory.attachment != "" && listHistory.attachmentType === "blob_uri"'><a :href='listHistory.url' target="_blank">{{listHistory.fileName}}</a></span> -->
                                        <span class="lnk" v-if='listHistory.attachment != "" && listHistory.attachmentType === "blob_uri"'>{{listHistory.fileName}}</span>
                                    </p>
                                    <p class="msg-review" v-if='listHistory.observations != null'>{{listHistory.observations}}</p>
                                </div>
                            </div>
                        </div>


                        <!-- CANCEL PAYMENT -->
                        <div class="cancel-payment" v-if='pendingPay.phasePurchaseStatus != "Paid" && pendingPay.phasePurchaseStatus != "Cancelled"'>
                            <button @click="cancelingPurchase = true" v-if="!pendingPay.load && !cancelingPurchase" class="btn-cancel-primary">{{getMyLanguage("client-space","activity.pending.payment.btn.cancelPurchase")}}</button>
                            <!-- <div class="btn-cancel-primary load" v-if="pendingPay.load"></div> -->

                            <div class="confirm-cancel" v-if="cancelingPurchase">
                                <p><strong>{{getMyLanguage("client-space","activity.pending.payment.cancel-purchase")}}</strong></p>
                                <div class="btn-cancel-primary" @click="cancelPurchase(pendingPay.phasePurchaseId)">{{getMyLanguage("client-space","client-space.account.modal.btn-yes")}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import apiInternational from '@/config/apiInternational.js';
    import { handleError } from '@/services/errorHandler.js'

    // import axios from 'axios';
    import QrcodeVue from 'qrcode.vue'
    import moment from "moment";
    export default {
        components: {
            QrcodeVue
        },

        data() {
            return {
                flagSelected: "en",
                load: true,
                dataHistory: [],
                dataPurchaseSepa: [],
                dataPendingPayment: null,

                errorsPut: {
                    upload: false
                },
                
                errorsPost: {
                    hash: false
                },

                pendingPay: {
                    modal: false,
                    load: false,
                    index: "",
                    reviewSuccess: false,
                    expired: true
                },

                qrCode:{
                    value: "",
                    size: 220
                },
                
                review: {},

                validation: {
                    hasReview: '',
                    Hash: '',
                    File: '',
                    Observations: ''
                },

                uploadReceipt: {
                    phasePurchaseId: "",
                    File: null,
                    selected: false,
                    load: false,
                    isImage: false,
                    resumeFile: false,
                    previewURLImage: ""
                },

                modalAgreement: {
                    status: false,
                    idBlockChain: "",
                    lnkBlockChain: ""
                },

                urlAgreement: "",
                blockPurchase: false,

                cancelingPurchase: false
            };
        },

        async mounted() {
            await apiInternational.get("/api/v1/ico/token_sale/purchases")
            .then((response) => {
                this.dataHistory = response.data;
                this.load = false
            })
            .catch((error) => {
                this.load = false
                handleError(error, this.flagSelected);
            });
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            loginStatus() {
                if (
                    localStorage.getItem("accessToken") === undefined ||
                    localStorage.getItem("accessToken") === null
                ) {
                    window.location.href = "/";
                }
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
                return moment(value).format(format);
            },

            // goPayment(saleId, quantity) {
            //     const languageRedirect = String(window.location.pathname).split("/")[1];
            //     window.location.href = "/" + languageRedirect + "/client-space/purchase?PhasePurchaseId=" + saleId + "&quantity=" + quantity;
            // },

            changePayment(value) {
                this.pendingPay = this.dataHistory[value]
                this.pendingPay.index = value
                this.uploadReceipt.File = null
                this.uploadReceipt.selected = false

                this.review.PhasePurchaseId = this.pendingPay.phasePurchaseId
                this.review.Receipt = ""
                this.review.Observations = ""
                
                this.validation.Hash = ''
                this.validation.File = ''
                this.validation.Observations = ''

                if ( this.pendingPay.reviews.length ){
                    this.validation.hasReview = true

                    if ( this.pendingPay.paymentType === "SEPA/SWIFT" ){
                        this.validation.File = true
                    } else{
                        this.validation.Hash = true
                    }

                } else {
                    this.validation.hasReview = false
                    this.validation.Observations = ""
                }

                if ( this.pendingPay.paymentType === "PIX" ){
                    if ( this.pendingPay.pixPayload != null ){
                        this.qrCode.value = this.pendingPay.pixPayload
                    } else {
                        this.qrCode.value = ""
                    }
                    // this.qrCode.value = "00020126460014BR.GOV.BCB.PIX0124henrysonvieira@gmail.com5204000053039865406100.005802BR5923Henryson Vieira de Lima6008Curitiba62130509qrCodePix63045793"
                }
                
                this.pendingPay.modal = true

                // CONDITION DATA
                this.pendingPay.expired = false
                // 
            },

            async copyInstruction(instruction) {
                try {
                    await navigator.clipboard.writeText(instruction);
                    this.$toast.success(this.getMyLanguage("client-space", "instructions.copied"));
                    setTimeout(this.$toast.clear, 3000);    
                }
                
                catch ($e) {
                    this.$toast.success(this.getMyLanguage("client-space", "instructions.nocopied"));
                    setTimeout(this.$toast.clear, 3000);
                }
            },
            
            copyError(){
                this.$toast.error(this.getMyLanguage("client-space", "instructions.nocopied"));
                    setTimeout(this.$toast.clear, 3000);
            },

            cancelPurchase(id) {
                this.pendingPay.load = true;
                apiInternational.delete(`/api/v1/ico/token_sale/cancel/${id}`)
                .then((response) => {
                    console.log(response)
                    console.clear()

                    this.cancelingPurchase = false
                    this.updatePurchases();
                })
                .catch((error) => {
                    this.pendingPay.load = false;
                    this.errorMessage = error.message;
                    console.error("There was an error!", JSON.stringify(error));
                    this.$toast.error(this.errorMessage);
                    setTimeout(this.$toast.clear, 3000);
                });
            },

            purchaseAgreement(idAgreement, idBlockChain,) {
                this.modalAgreement.status = true;
                this.modalAgreement.idBlockChain = idBlockChain
                this.modalAgreement.lnkBlockChain = `https://ipfs.io/ipfs/${idBlockChain}?filename=Agreement_DNE_International.pdf`
                this.urlAgreement = `/en/client-space/agreement/${idAgreement}`
            },

            uploadFileReceipt() {
                this.validation.File = true
                this.validation.Observations = true

                this.uploadReceipt.File = this.$refs.fileReceipt.files[0];
                console.log(this.uploadReceipt)

                if ( this.uploadReceipt.File != null ){
                    this.uploadReceipt.selected = "fileSelected"
                    
                    const fileURL = URL.createObjectURL(this.uploadReceipt.File);
                    this.uploadReceipt.previewURLImage = fileURL;
                    this.uploadReceipt.resumeFile = true
                    
                    setTimeout(() => {
                    URL.revokeObjectURL(fileURL);
                    }, 10000);
                }

                if (this.uploadReceipt.File.type.startsWith('image/')) {
                    this.uploadReceipt.isImage = true
                } else {
                    this.uploadReceipt.isImage = false
                }

                console.log(this.uploadReceipt.isImage)
            },

            formatFileSize(fileSize) {
                if (fileSize === 0) return '0 Bytes';
                const k = 1024;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                const i = Math.floor(Math.log(fileSize) / Math.log(k));
                return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
            },

            validateFormReview(){
                // HASH
                if ( (this.review.Receipt != null || this.review.Receipt != "") && !this.validation.hasReview && this.pendingPay.paymentType === "Crypto" ){
                    if ( this.review.Receipt.match(/^0x([A-Fa-f0-9]{64})$/) ) {
                        this.validation.Hash = true
                        this.validation.Observations = true
                    } else{
                        this.validation.Hash = false
                    }
                }

                // UPLOAD
                if ( !this.validation.hasReview && this.validation.File != true && this.pendingPay.paymentType === "SEPA/SWIFT" ){
                    if ( (this.uploadReceipt.File != null || this.uploadReceipt.File != "") ){
                        this.validation.File = false
                    }
                }


                // OBSERVATIONS
                if ( this.validation.hasReview ){
                    if ((((this.uploadReceipt.File != null || this.uploadReceipt.File != "") || (this.review.Receipt != null || this.review.Receipt != ""))) && this.review.Observations === ""){
                        this.validation.Observations = false
                    } else{
                        this.validation.Observations = true
                    }
                }


                // VALIDATED
                if ( (this.validation.Hash === true || this.validation.File === true) && this.validation.Observations != false ){
                    this.sendReview()
                }
            },

            // validateFormReview(){
            //     if ( this.review.Receipt != "" ){
            //         if ( this.review.Receipt.match(/^0x([A-Fa-f0-9]{64})$/) ) {
            //             this.validation.Hash = true
            //             this.validation.Observations = true
            //             this.sendReview()
            //         } else{
            //             this.validation.Hash = false
            //             this.validation.Observations = true
            //         }
            //     }
                
            //     else if ( (this.review.Receipt === "") && (this.review.Observations === "") ){
            //         this.validation.Observations = false
            //         this.validation.Hash = true
            //     }
                
            //     else{
            //         this.validation.Hash = true
            //         this.validation.Observations = true
            //         this.sendReview()
            //     }
            // },

            sendReview(){
                this.pendingPay.load = true
                const formData = new FormData();
                formData.append('File', this.uploadReceipt.File);
                formData.append('PhasePurchaseId', this.review.PhasePurchaseId);
                formData.append('Receipt', this.review.Receipt);
                formData.append('Observations', this.review.Observations);
                
                const token = localStorage.getItem('accessToken');
                const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}` };
                apiInternational.put("/api/v1/ico/token_sale/purchase", formData, { headers })
                .then(response => {
                    console.log(response)
                    console.clear()

                    this.pendingPay.load = false
                    this.pendingPay.reviewSuccess = true
                    this.uploadReceipt.resumeFile = false

                    this.$toast.success(this.getMyLanguage("client-space","instructions.receipt.upload.success.txt"))

                    this.updatePurchases();
                    
                    setTimeout(() => {
                        this.pendingPay.reviewSuccess = false
                    }, 5000);
                })
                .catch(error => {
                    this.errorsPut.upload = error.response.data
                    this.errorsPut.upload = JSON.stringify(error.response.status)
                    
                    this.pendingPay.load = false
                })
            },

            async updatePurchases(){
                const updatePurchases = await apiInternational.get('api/v1/ico/token_sale/purchases')
                this.dataHistory = updatePurchases.data
                
                this.changePayment(this.pendingPay.index)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    };
</script>

<style src="@/assets/scss/_list-history.scss" lang="scss" scoped />