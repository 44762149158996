<template>
    <div class="dne-support">
        <div id="dne"></div>
        <Header />


        <!-- FORM FAQ -->
        <section class="form-faq" v-if="!ticketOpen">
            <div class="container" v-if="!load">
                <div class="row">
                    <div class="col-12 cont-support">
                        <h2 class="title-secondary">{{getMyLanguage("support","support.form.title")}}</h2>
                    </div>
                </div>

                <form method="post" @submit.prevent="formSend()">
                    <div class="row form-support">
                        
                        <div class="col-12 col-lg-6">
                            <label class="formName">
                                {{getMyLanguage("support","support.form.lbl-name")}}
                                <div class="form form-disabled" v-if="this.$store.state.statusLogin">{{$store.state.profile.profile_full_name}}</div>
                                <input v-model="formSupport.name" type="text" class="form" maxlength="80" autocomplete="off"  v-if="!this.$store.state.statusLogin">
                            </label>
                        </div>
                        
                        <div class="col-12 col-lg-6">
                            <label class="formEmail">
                                {{getMyLanguage("support","support.form.lbl-email")}}
                                <div class="form form-disabled" v-if="this.$store.state.statusLogin">{{ maskedEmail($store.state.profile.profile_email) }}</div>
                                <input v-model="formSupport.email" type="text" class="form" maxlength="80" autocomplete="off"  v-if="!this.$store.state.statusLogin">
                            </label>
                        </div>
                        
                        <div class="col-12 col-lg-6">
                            <label class="noMargin">{{getMyLanguage("support","support.form.lbl-phone")}}</label>

                            <div class="formPhone" v-if="this.$store.state.statusLogin">
                                <div class="select-ddi disabled">
                                    <div class="selected-ddi">
                                        <div class="flag" :class="{ noFlag: !loadFlag }">
                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${formSupport.flag}/flags`" v-if="loadFlag">
                                        </div>
                                        <span class="ddi">{{$store.state.profile.profile_phone_country_ddi}}</span>
                                    </div>
                                </div>

                                <label>
                                    <div class="form form-disabled">{{ maskedPhone($store.state.profile.profile_phone_number) }}</div>
                                </label>
                            </div>
                            
                            <div class="formPhone" :class="selectedCountryDDI.active" v-if="!this.$store.state.statusLogin">
                                <div class="select-ddi" v-if="selectedCountryDDI.changeMask === true" :class="{ noSelected: !selectedCountryDDI.countryDDISelected }">
                                    <div class="open-select-ddi" @click="selectedCountryDDI.openSelect = true"></div>
                                    <div class="close-select-ddi" v-if="selectedCountryDDI.openSelect === true" @click="selectedCountryDDI.openSelect = false"></div>

                                    <div class="selected-ddi">
                                        <div class="flag" :class="{ noFlag: !selectedCountryDDI.countryDDISelected }">
                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDI.flag}/flags`" v-if="selectedCountryDDI.flag != ''">
                                        </div>
                                        <span class="ddi">+{{selectedCountryDDI.ddi}}</span>
                                    </div>
                                    
                                    <div class="scroll" v-if="selectedCountryDDI.openSelect === true">
                                        <ul class="list-ddi">
                                            <li v-for="(ddiList,index) in countriesTranslated" :key="index" @click="(formSupport.PhoneCountryId = ddiList.id), (selectedCountryDDI.phoneMask = ddiList.phoneMask), (this.changeMask()), (selectedCountryDDI.ddi = ddiList.ddi), (selectedCountryDDI.flag = ddiList.Flag), (selectedCountryDDI.countryDDISelected = true), (selectedCountryDDI.openSelect = false)">
                                                <div class="flag">
                                                    <img class="anime" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${ddiList.Flag}/flags`">
                                                </div>
                                                <span class="name-country">{{ countryTranslate(ddiList.IsoCode) }}</span>
                                                <span class="ddi" >(+{{ddiList.DDI}})</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <label v-if="selectedCountryDDI.changeMask === true">
                                    <span class="selectDDI" v-if="!selectedCountryDDI.countryDDISelected" @click="selectedCountryDDI.openSelect = true"><span>{{getMyLanguage("support","support.form.instruction-ddi")}}</span></span>
                                    <input v-if="selectedCountryDDI.countryDDISelected" v-model="formSupport.phoneNumber" id="phoneNumber" type="text" class="form" v-mask="selectedCountryDDI.phoneMask" :placeholder='getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.phone")' maxlength="25" autocomplete="off" >
                                </label>
                                
                                <div class="load" v-if="selectedCountryDDI.changeMask === false"></div>

                            </div>
                        </div>

                        <div class="col-12 col-lg-6">
                            <label class="formSubject">
                                {{getMyLanguage("support","support.form.lbl-subject")}}
                                <select class="form" v-model="formSupport.subject">
                                    <option :value='getMyLanguage("support","support.form.subject.option1")'>{{getMyLanguage("support","support.form.subject.option1")}}</option>
                                    <option :value='getMyLanguage("support","support.form.subject.option2")'>{{getMyLanguage("support","support.form.subject.option2")}}</option>
                                    <option :value='getMyLanguage("support","support.form.subject.option3")'>{{getMyLanguage("support","support.form.subject.option3")}}</option>
                                    <option :value='getMyLanguage("support","support.form.subject.option4")'>{{getMyLanguage("support","support.form.subject.option4")}}</option>
                                    <option :value='getMyLanguage("support","support.form.subject.option5")'>{{getMyLanguage("support","support.form.subject.option5")}}</option>
                                </select>
                            </label>
                        </div>
                        
                        <div class="col-12">
                            <label class="formMessage">
                                {{getMyLanguage("support","support.form.lbl-message")}}
                                <span class="characters">{{formSupport.message.length}}/500</span>
                                <textarea class="form" v-model="formSupport.message" maxlength="500"></textarea>
                            </label>

                            <div class="msgError">
                                <span class="nameError">{{ getMyLanguage("errors", "errors.nameError") }}</span>
                                <span class="emailError">{{ getMyLanguage("errors", "errors.emailError") }}</span>
                                <span class="phoneError">{{ getMyLanguage("errors", "errors.phoneError") }}</span>
                                <span class="messageError">{{ getMyLanguage("errors", "errors.messageError") }}</span>
                            </div>

                            <div class="btnSend">
                                <button type="submit" class="btn-primary">{{getMyLanguage("support","support.form.btn-send")}}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="load" v-if="load"></div>
        </section>


        <!-- SUCCESS -->
        <section class="ticket-success" v-if="ticketOpen">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-10 col-lg-6">
                        <div class="box-success">
                            <h2 class="title-secondary">{{getMyLanguage("support","support.success.title")}}</h2>
                            <p>{{getMyLanguage("support","support.success.text")}}</p>
                            <p>{{getMyLanguage("support","support.success.protocol")}}: <strong>13513515</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
    const $ = require('jquery')
    window.$ = $
    
    $(document).on("click", ".question h6", function(){
        $('.question').removeClass('active');
        $('.question p').hide();
        $(this).parent().addClass('active');
        $('.question.active p').show();
    });

    $(document).on("click", ".question.active h6", function(){
        $('.question p').hide();
        $('.question').removeClass('active');
    });

    import Header from '@/components/Header.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
    import countriesList from "@/services/listCountries.js";

    import apiPlatform from '@/config/apiPlatform.js'

    export default {
        components: {
            Header
        },
        data (){
            return {
                ticketOpen: false,
                dataCountry: [],
                selectedCountryDDI: {
                    changeMask: true,
                    phoneMask: '',
                    countryDDISelected: false,
                    flag: ''
                },
                formSupport: {
                    phoneNumber: "",
                    subject: "",
                    message: "",
                    email: "",
                    name: ""
                },
                load: false,
                loadFlag: false
            }
        },
        
        async mounted(){
            // this.goTop();

            setTimeout(() => {
                this.formSupport.languageId = this.$store.state.SelectLanguage[0].id
            }, 3000);


            // COUNTRY
            const resumeClientCountry = await apiPlatform.get('api/v1/platform/country')
            this.dataCountry = resumeClientCountry.data

            setTimeout(() => {
                this.ordercountries()
            }, 100);


            setTimeout(() => {
                this.formSupport.subject = this.getMyLanguage("support","support.form.subject.option1")

                this.statusLogin();
            }, 1000);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.support");
                document.head.querySelector('meta[name=description]').content = this.getMyLanguage("seo","title.description.support")
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            maskedEmail(email) {
                if (!email) return '';
                const [localPart, domain] = email.split('@');
                if (!localPart || !domain) return email;
                const visibleStart = localPart.slice(0, 2);
                const maskedLocalPart = `${visibleStart}****`;
                return `${maskedLocalPart}@${domain}`;
            },

            maskedPhone(phoneNumber) {
                if (!phoneNumber) return ''
                const visibleStart = phoneNumber.slice(0, 2)
                const visibleEnd = phoneNumber.slice(-2)

                return `${visibleStart}****${visibleEnd}`
            },


            // COUNTRIES
            countryTranslate(code){
                return countriesList(code, this.$store.state.SelectLanguage.code)
            },

            ordercountries() {
                this.countriesTranslated = [...this.dataCountry];
                
                this.countriesTranslated.forEach(lang => {
                    lang.translatedCountry = this.countryTranslate(lang.IsoCode);
                });

                this.countriesTranslated.sort((a, b) => {
                    return a.translatedCountry.toLowerCase().localeCompare(b.translatedCountry.toLowerCase());
                });
            },


            statusLogin(){
                if ( this.$store.state.statusLogin ){
                    this.formSupport.name = this.$store.state.profile.profile_full_name
                    this.formSupport.email = this.$store.state.profile.profile_email
                    this.formSupport.flag = this.dataCountry.filter(flagLog => flagLog.Id === this.$store.state.profile.profile_phone_country_id)[0].Flag
                    
                    setTimeout(() => {
                        this.loadFlag = true
                    }, 1000);
                }
            },

            // goTop(){
            //     window.location.hash="dne";
            // },

            changeMask(){
                this.selectedCountryDDI.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDI.changeMask = true
                }, 1000);
            },

            formSend(){
                if ( this.$store.state.statusLogin ){
                    this.validateFormLogged()
                } else {
                    this.validateForm()
                }
            },
            
            validateForm(){                
                // VALIDATE NAME
                var youName = $('.form-support .formName input.form').val();
                youName = youName.trim().split(' ').length;

                if ( youName < 2 ) {
                    $('.form-support .formName input.form').addClass("error");
                    $('.msgError .nameError').css('display','block');
                } else {
                    $('.msgError .nameError').css('display','none');
                }

                $(document).on('blur','.form-support .formName input.form', function(){
                    youName = $('.form-support .formName input.form').val();
                    youName = youName.trim().split(' ').length;

                    if ( youName > 1 ){
                        $('.form-support .formName input.form').removeClass("error");
                        $('.msgError .nameError').css('display','none');
                    }
                })

                // VALIDATE YOUR EMAIL
                var yourEmail = $('.form-support .formEmail input.form').val();

                if (yourEmail == ""){
                    $('.form-support .formEmail input.form').addClass("error");
                    $('.msgError .emailError').css('display','block');
                } else {
                    $('.msgError .emailError').css('display','none');
                }
                
                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (regex.test(yourEmail)) {
                    $('.form-support .formEmail input.form').removeClass("error");
                    $('.msgError .emailError').css('display','none');
                } else {
                    $('.form-support .formEmail input.form').addClass("error");
                    $('.msgError .emailError').css('display','block');
                }

                $(document).on('blur','.form-support .formEmail input.form', function(){
                    yourEmail = $('.form-support .formEmail input.form').val();

                    if ( yourEmail != "" && regex.test(yourEmail) ){
                        $('.form-support .formEmail input.form').removeClass("error");
                        $('.msgError .emailError').css('display','none');
                    }
                })


                // VALIDATE PHONE
                var phone = this.formSupport.phoneNumber

                if (phone == ""){
                    $('.form-support .formPhone').addClass("error");
                    $('.msgError .phoneError').css('display','block');
                } else {
                    $('.msgError .phoneError').css('display','none');
                }

                $(document).on('blur','.form-support .formPhone input.form', function(){
                    phone = $('.form-support .formPhone input.form').val();

                    if ( phone != "" ){
                        $('.msgError .phoneError').css('display','none');
                        $('.form-support .formPhone').removeClass("error");
                    }
                })


                // VALIDATE MESSAGE
                var message = $('.form-support .formMessage textarea.form').val();

                if ( message === "" || message.length <= 10 ) {
                    $('.form-support .formMessage textarea.form').addClass("error");
                    $('.msgError .messageError').css('display','block');
                } else {
                    $('.msgError .messageError').css('display','none');
                }

                $(document).on('blur','.form-support .formMessage textarea.form', function(){
                    message = $('.form-support .formMessage textarea.form').val();

                    if ( message.length > 10 ){
                        $('.form-support .formMessage textarea.form').removeClass("error");
                        $('.msgError .messageError').css('display','none');
                    }
                })


                // OK
                if ( $('.form').hasClass('error') ) {
                    // window.alert('há erro(s)');
                } else{
                    this.openTicket();
                }
            },
            

            // VALIDADE FORM LOGGED
            validateFormLogged(){
                var message = $('.form-support .formMessage textarea.form').val();

                if ( message === "" || message.length <= 10 ) {
                    $('.form-support .formMessage textarea.form').addClass("error");
                    $('.msgError .messageError').css('display','block');
                } else {
                    $('.msgError .messageError').css('display','none');
                }

                $(document).on('blur','.form-support .formMessage textarea.form', function(){
                    message = $('.form-support .formMessage textarea.form').val();

                    if ( message.length > 10 ){
                        $('.form-support .formMessage textarea.form').removeClass("error");
                        $('.msgError .messageError').css('display','none');
                    }
                })


                // OK
                if ( $('.form').hasClass('error') ) {
                    // window.alert('há erro(s)');
                } else{
                    this.openTicket();
                }
            },

            openTicket(){
                this.load = true

                setTimeout(() => {
                    this.load = false
                    this.ticketOpen = true
                }, 1000);
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                    this.ordercountries()
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.support");
            document.head.querySelector('meta[name=description]').content = this.getMyLanguage("seo","title.description.support")
        },
    }
</script>

<style src="./custom-support.scss" lang="scss" scoped />