import axios from 'axios';

const token = localStorage.getItem('accessToken');
let companyReference = 0

if (localStorage.getItem('companyReference') === "" || localStorage.getItem('companyReference') === null) {
    companyReference = localStorage.setItem("companyReference", 0)
} else {
    companyReference = localStorage.getItem('companyReference')
}

const apiInternational = axios.create({
    baseURL: process.env.VUE_APP_INTERNATIONAL_API,
    headers: { 'Authorization': `bearer ${token}`, 'Company-Reference': companyReference, 'X-Client-Identifier': "DneToken" }
});

export default apiInternational;