<template>
    <div class="home compliance-page">
        <div id="dne"></div>
        <Header />

        <section class="compliance">

            <div class="load load-white load-200" v-if="!showContent"></div>

            <div class="container" v-if="showContent && errorsGet.dataAccount != false">
                <div class="box-error">
                    <h3 class="title-secondary">
                        {{ getMyLanguage("box-error", "title-error") }}
                    </h3>

                    <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                    
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataAccount}}
                    </div>
                </div>
            </div>

            <div class="container" v-if="showContent && !errorsGet.dataAccount">
                <h2 class="title-secondary">
                    {{getMyLanguage("compliance","compliance.title")}}
                </h2>
                
                <div class="box-compliance">
                    <div class="msg msg-success" v-html='getMyLanguage("compliance","compliance.msg")'></div>
                    
                    <!-- PERSONAL INFORMATION -->
                    <div class="box-registration" id="errorAnc">
                        <div class="formBox">

                            <h3 class="subtitle">
                                {{getMyLanguage("compliance","compliance.dnePlatform.information")}}
                            </h3>

                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <label class="formFullName">
                                        {{getMyLanguage("compliance","compliance.lbl.fullName")}}
                                        <span class="form formDisabled">{{dataAccount.fullName}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formYourEmail">
                                        {{getMyLanguage("compliance","compliance.lbl.email")}}
                                        <span class="form formDisabled">{{dataAccount.email}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formPhone">
                                        {{getMyLanguage("compliance","compliance.lbl.phone")}}
                                        <span class="form formDisabled">{{dataAccount.phoneNumber}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formResidenceCountry">
                                        {{getMyLanguage("compliance","compliance.lbl.country")}}
                                        <span class="form formDisabled">
                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${dataAccount.countryFlag}/flags`" class="flag" v-if="dataAccount.countryFlag != false && dataAccount.countryFlag != null">
                                            {{ countryTranslate(dataAccount.countryCode) }}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- IDENTIFICATION DATA -->
                    <div class="box-registration identification-data">
                        <div class="formBox">

                            <h3 class="subtitle">
                                {{getMyLanguage("compliance","compliance.additionalData")}}
                            </h3>

                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <label class="formIdNumber">
                                        <span>{{getMyLanguage("compliance","compliance.lbl." + dataAccount.documentType)}}</span>
                                        <span class="form formDisabled">{{dataAccount.documentNumber}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formExpirationDate">
                                        {{getMyLanguage("compliance", "compliance.lbl.validUntil")}}
                                        <span class="form formDisabled">{{ dateTime(dataAccount.documentValidThru) }}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formCountry">
                                        {{getMyLanguage("compliance","compliance.lbl.issuing-country")}}
                                        <span class="form formDisabled">
                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${dataAccount.documentIssuerFlag}/flags`" class="flag" v-if="dataAccount.documentIssuerFlag != false && dataAccount.documentIssuerFlag != null">
                                            {{ countryTranslate(dataCountry.filter(country => country.Id == dataAccount.documentIssuerId)[0].IsoCode) }}
                                        </span>
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label class="formPersonDOB">
                                        {{getMyLanguage("client-space", "client-space.account.lbl.birthdate")}}
                                        <span class="form formDisabled">{{ dateTime(dataAccount.dob) }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- ADDRESS -->
                    <div class="box-registration address-compliance">
                        <div class="formBox">

                            <h3 class="subtitle">
                                {{getMyLanguage("compliance","compliance.address")}}
                            </h3>

                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <label class="formAddress01 noIcon">
                                        {{getMyLanguage("compliance","compliance.address.address01")}}
                                        <span class="form formDisabled">
                                            {{dataAccount.address.address1}}
                                        </span>
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label class="formAddress02 noIcon">
                                        {{getMyLanguage("compliance","compliance.address.address02")}}
                                        <span class="form formDisabled">
                                            {{dataAccount.address.address2}}
                                        </span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formPostalCode noIcon">
                                        {{getMyLanguage("compliance","compliance.address.postalCode")}}
                                        <span class="form formDisabled">
                                            {{dataAccount.address.postalCode}}
                                        </span>
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label class="formCity noIcon">
                                        {{getMyLanguage("compliance","compliance.address.city")}}
                                        <span class="form formDisabled">
                                            {{dataAccount.address.city}}
                                        </span>
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label class="formStateProvince noIcon">
                                        {{getMyLanguage("compliance","compliance.address.district")}}
                                        <span class="form formDisabled">
                                            {{dataAccount.address.district}}
                                        </span>
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label class="formCountry noIcon">
                                        {{getMyLanguage("compliance","compliance.address.country")}}
                                        <span class="form formDisabled">
                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${dataAccount.address.countryFlag}/flags`" class="flag" v-if="dataAccount.address.countryFlag != false && dataAccount.address.countryFlag != null">
                                            {{ countryTranslate(dataCountry.filter(country => country.Name == dataAccount.address.country)[0].IsoCode) }}
                                        </span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formTaxNumber noIcon" :class="{required: errorsValidate.taxNumber}">
                                        {{getMyLanguage("compliance","compliance.lbl.taxNumber")}}
                                        <small>{{getMyLanguage("compliance","compliance.lbl.taxNumberCompanyCountry")}}</small>

                                        <input type="text" class="form" :placeholder='getMyLanguage("compliance","compliance.lbl.taxNumberDoc")' v-model="dataCompliance.taxNumber" @blur="errorsValidate.taxNumber = false">
                                        <small class="error" v-if="errorsValidate.taxNumber">{{ getMyLanguage("errors","errors.required") }}</small>
                                    </label>
                                </div>

                                <!-- ISSUER COUNTRY -->
                                <!-- <div class="col-12 col-lg-6">
                                    <div class="autocomplete formIssuerCountry">
                                        <label :class="{required: errorsValidate.taxNumberIssuerCountry}">
                                            {{getMyLanguage("compliance","compliance.lbl.IssuerCountry")}}

                                            <div class="openAutocomplete" v-if="!listCountryStatus" @click="listCountry(true)"></div>
                                            <div class="closeAutocomplete closeDropListCountry" v-if="listCountryStatus" @click="listCountry(false)"></div>

                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlag}/flags`" class="flag-selected" v-if="selectedCountryFlag != false && selectedCountryFlag != null">
                                            <input type="text" id="issuerCountry" class="form" :class="{selected: selectedCountryFlag != false}" @keyup="filterListCountry" @blur="listCountry(false)" :value="countryTranslate(selectedCountry.IsoCode)" :placeholder='getMyLanguage("compliance","compliance.lbl.selectCountry")' :title='getMyLanguage("compliance","compliance.lbl.IssuerCountry")' autocomplete="off">

                                            <ul id="drop-list" v-if="listCountryStatus">
                                                <li v-for="(itemList,index) in countriesTranslated" :key="index" @click="changeTaxnumberIssuerCountry(dataCompliance.taxNumberIssuerCountry = itemList.Id, selectedCountry.IsoCode = itemList.IsoCode, selectedCountryFlag = itemList.Flag)">
                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.Flag}/flags`">
                                                    <a>{{ countryTranslate(itemList.IsoCode)}}</a>
                                                </li>
                                            </ul>

                                            <small class="error" v-if="errorsValidate.taxNumberIssuerCountry">{{ getMyLanguage("errors","errors.residenceError") }}</small>
                                        </label>
                                    </div>
                                </div> -->

                                <div class="col-12">
                                    <label class="additional-address-data noIcon">
                                        {{getMyLanguage("compliance","compliance.lbl.additional-address")}}
                                        <span class="cont">{{dataCompliance.addressAdditionalInfo.length}}/250</span>
                                        <textarea class="form" v-model="dataCompliance.addressAdditionalInfo" maxlength="250" :placeholder='getMyLanguage("compliance","compliance.placeholder.additional-address")'></textarea>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="msgSupport" v-if='dataCompliance.wrongData != ""'>
                        <p><strong>{{getMyLanguage("compliance","compliance.modal.noConfirm.title")}}</strong></p>
                        {{dataCompliance.wrongData}}
                    </div>

                    <div class="accepts" v-if="!errorsPost.dataCompliance">
                        <div class="form-group-check">
                            <input type="checkbox" id="acceptTermsLG" class="form-check" v-model="accept.terms">
                            
                            <label for="acceptTermsLG" class="form-check-label" :class="{error: errorsValidate.acceptTerms}">
                                <small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms") }}</small>
                            </label>
                            
                            <a :href="`/${flagSelected}/terms-and-conditions`" target="_blank" class="lnk-terms-group-check">
                                {{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms.lnk") }}
                            </a>
                        </div>
                        
                        <div class="form-group-check">
                            <input type="checkbox" id="acceptNotify" class="form-check" v-model="accept.notify">
                            
                            <label for="acceptNotify" class="form-check-label" :class="{error: errorsValidate.acceptNotify}">
                                <small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-notify") }}</small>
                            </label>
                        </div>
                        
                        <div class="form-group-check">
                            <input type="checkbox" id="acceptGDPR" class="form-check" v-model="accept.gdpr">
                            
                            <label for="acceptGDPR" class="form-check-label" :class="{error: errorsValidate.acceptGdpr}">
                                <small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-gdpr") }}</small>
                            </label>
                        </div>
                    </div>

                    <div class="row" v-if="errorsValidate.taxNumber || errorsValidate.taxNumberIssuerCountry || errorsValidate.acceptTerms || errorsValidate.acceptNotify || errorsValidate.acceptGdpr ">
                        <div class="col-12">
                            <div class="errorsForm">
                                <div>
                                    <p>
                                        <strong>{{getMyLanguage("compliance","compliance.errorValidateForm")}}</strong>
                                    </p>

                                    <p v-if="errorsValidate.taxNumber">
                                        <small>- {{ getMyLanguage("compliance","compliance.errorTaxNumber") }}</small>
                                    </p>

                                    <p v-if="errorsValidate.taxNumberIssuerCountry">
                                        <small>- {{ getMyLanguage("compliance","compliance.errorIssuerCountry") }}</small>
                                    </p>

                                    <p v-if="errorsValidate.acceptTerms || errorsValidate.acceptNotify || errorsValidate.acceptGdpr">
                                        <small>- {{ getMyLanguage("compliance","compliance.errorAccepts") }}</small>
                                    </p>
                                </div>
                            </div>
                            <p></p>
                        </div>
                    </div>

                    <div class="container" v-if="errorsPost.dataCompliance != false">
                        <div class="box-error">
                            <h3 class="title-secondary">
                                {{ getMyLanguage("box-error", "title-error") }}
                            </h3>

                            <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
                            
                            <div class="cod-error">
                                Cód.: {{errorsPost.dataCompliance}}
                            </div>
                        </div>
                    </div>

                    <div class="row bts-compliance" v-if="!errorsPost.dataCompliance">
                        <div class="col-12 col-lg-6 col-btn-send">
                            <div id="btnRegister" v-if="!accept.terms || !accept.notify || !accept.gdpr" class="btn-primary btn-disabled" @click="validateForm()">
                                {{ getMyLanguage("client-space", "instructions.receipt.send") }}
                            </div>

                            <div id="btnRegister" v-if="accept.terms && accept.notify && accept.gdpr && !load" @click="validateForm()" class="btn-primary">
                                {{ getMyLanguage("client-space", "instructions.receipt.send") }}
                            </div>

                            <div class="btn-primary load" v-if="load"></div>
                        </div>
                        
                        <div class="col-12 col-lg-6 col-btn-cancel">
                            <div class="btn-cancel-compliance" @click="modalNoConfirm.modal = true,modalNoConfirm.send = false">
                                {{getMyLanguage("compliance","compliance.btn-cancel")}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- MODAL CANCEL COMPLIANCE -->
            <div class="modal modal-noConfirm" v-if="modalNoConfirm.modal">
                <div class="modal-cont">
                    <div class="modal-close" @click="modalNoConfirm.modal = false"></div>
                    <div class="box">
                        <div class="scroll-modal">

                            <!-- FORM -->
                            <div class="form-content" v-if="!modalNoConfirm.send">
                                <h2 class="title-secondary">
                                    {{getMyLanguage("compliance","compliance.modal.noConfirm.title")}}
                                </h2>

                                <label>{{getMyLanguage("compliance","compliance.modal.noConfirm.description-error")}}</label>
                                
                                <textarea class="form" v-model="dataCompliance.wrongData"></textarea>

                                <div class="btnModal">
                                    <div class="btn-primary" @click="modalNoConfirm.send = true">
                                        {{getMyLanguage("compliance","compliance.modal.noConfirm.btn-send")}}
                                    </div>
                                </div>
                            </div>


                            <!-- SUCCESS -->
                            <div class="success" v-if="modalNoConfirm.send">
                                <h2 class="title-secondary">
                                    {{getMyLanguage("compliance","compliance.modal.noConfirm.success.title")}}
                                </h2>
                                
                                <div class="box-success">
                                    <p v-html='getMyLanguage("compliance","compliance.modal.noConfirm.success.msg")'></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </section>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Header.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
    import countriesList from "@/services/listCountries.js";

    import apiInternational from '@/config/apiInternational.js'
    import apiPlatform from '@/config/apiPlatform.js'
    import moment from 'moment';

    import { handleError } from '@/services/errorHandler.js'
  
    export default {
        components: {
            Header
        },

        data(){
            return{
                showContent: false,
                load: false,
                flagSelected: "en",

                errorsGet: {
                    dataAccount: false
                },

                errorsPost: {
                    dataCompliance: false
                },

                dataAccount: {
                    countryFlag: false,
                    documentIssuerFlag: false,
                    address: {
                        countryFlag: false
                    }
                },
                
                dataCountry: [],
                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryFlag: false,

                accept: {
                    terms: false,
                    notify: false,
                    gdpr: false
                },

                dataCompliance: {
                    addressAdditionalInfo: "",
                    wrongData: "",
                    taxNumberIssuerCountry: ""
                },

                modalNoConfirm: {
                    modal: false,
                    load: false,
                    send: false
                },

                errorsValidate: {
                    taxNumber: false,
                    taxNumberIssuerCountry: false
                }
            }
        },
        
        async mounted(){
            await apiInternational.get('/api/v1/platform/account/international/compliance')
            .then(response => {
                this.dataAccount = response.data
                console.log(this.dataAccount)
                this.dataCompliance.taxNumber = this.dataAccount.taxNumber

                // this.dataCompliance.taxNumberIssuerCountry = this.dataAccount.taxNumberIssuerCountryId
                // this.selectedCountry.name = this.dataAccount.taxNumberIssuerCountry
                // this.selectedCountryFlag = this.dataAccount.taxNumberIssuerCountryFlag

                this.dataCompliance.taxNumberIssuerCountry = this.dataAccount.address.countryId
                this.selectedCountry.name = this.dataAccount.address.country
                this.selectedCountryFlag = this.dataAccount.address.countryFlag

                this.dataCompliance.wrongData = this.dataAccount.wrongData
                
                if ( this.dataAccount.address.addressAdditionalInfo === null ){
                    this.dataCompliance.addressAdditionalInfo = ""
                } else{
                    this.dataCompliance.addressAdditionalInfo = this.dataAccount.address.addressAdditionalInfo
                }

                this.showContent = true
            })
            .catch(error => {
                this.errorsGet.dataAccount = error.response.data
                this.showContent = true

                handleError(error, this.flagSelected);
            })

            
            // COUNTRY
            const resumeClientCountry = await apiPlatform.get('api/v1/platform/country')
            this.dataCountry = resumeClientCountry.data

            setTimeout(() => {
                this.ordercountries()
            }, 100);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.account.compliance");
                document.head.querySelector('meta[name=description]').content = ''
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            
            // COUNTRIES
            countryTranslate(code){
                return countriesList(code, this.$store.state.SelectLanguage.code)
            },

            ordercountries() {
                this.countriesTranslated = [...this.dataCountry];
                
                this.countriesTranslated.forEach(lang => {
                    lang.translatedCountry = this.countryTranslate(lang.IsoCode);
                });

                this.countriesTranslated.sort((a, b) => {
                    return a.translatedCountry.toLowerCase().localeCompare(b.translatedCountry.toLowerCase());
                });
            },


            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            listCountry(status){
                setTimeout(() => {
                    this.listCountryStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        document.getElementById('issuerCountry').focus()
                    }, 10);
                }
            },

            filterListCountry() {
                var input, filter, ul, li, a, i, txtValue;
                input = document.getElementById("issuerCountry");
                filter = input.value.toUpperCase();
                ul = document.getElementById("drop-list");
                li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
                }
            },

            changeTaxnumberIssuerCountry(id,name,flag){
                this.dataCompliance.taxNumberIssuerCountry = id
                this.selectedCountry.name = name
                this.selectedCountryFlag = flag
                this.listCountryStatus = false
                this.errorsValidate.taxNumberIssuerCountry = false
            },

            validateForm(){
                if ( this.dataCompliance.taxNumber === "" ){
                    this.errorsValidate.taxNumber = true
                    document.getElementById("errorAnc").scrollIntoView();
                } else{
                    this.errorsValidate.taxNumber = false
                }
                
                if ( this.dataCompliance.taxNumberIssuerCountry === null ){
                    this.errorsValidate.taxNumberIssuerCountry = true
                    document.getElementById("errorAnc").scrollIntoView();
                } else{
                    this.errorsValidate.taxNumberIssuerCountry = false
                }

                if ( !this.accept.terms ){
                    this.errorsValidate.acceptTerms = true
                } else{
                    this.errorsValidate.acceptTerms = false
                }
                
                if ( !this.accept.notify ){
                    this.errorsValidate.acceptNotify = true
                } else{
                    this.errorsValidate.acceptNotify = false
                }
                
                if ( !this.accept.gdpr ){
                    this.errorsValidate.acceptGdpr = true
                } else{
                    this.errorsValidate.acceptGdpr = false
                }
                
                if ( !this.errorsValidate.taxNumber && !this.errorsValidate.taxNumberIssuerCountry && !this.errorsValidate.acceptTerms && !this.errorsValidate.acceptNotify && !this.errorsValidate.acceptGdpr ) {
                    this.register()
                }
            },

            register(){
                this.load = true
                const compliance = this.dataCompliance;

                apiInternational.post('/api/v1/platform/account/international/compliance', compliance)
                .then(response => {
                    this.registerId = response.data.id;
                    // window.location.href = `/${this.flagSelected}/account/compliance-success`
                    window.location.href = `/${this.flagSelected}/account/ubo/person`
                })
                .catch(error => {
                    // this.errorMessage = error.message;
                    console.log(error)

                    this.errorsPost.dataCompliance = error.response.status

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                    this.ordercountries()
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.compliance");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-compliance.scss" lang="scss" scoped />