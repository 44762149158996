<template>
    <section class="referral-plan">
        <div class="load" v-if="load"></div>

        <div class="container" v-if="errosGet != false && !load">
            <div class="box-pending">
                <!-- <h3 class="title-secondary">{{ getMyLanguage("box-error","title-error") }}</h3> -->
                <p>{{ getMyLanguage("box-error","msg-not-enabled") }}</p>
            </div>
        </div>

        <div class="container" v-if="!errosGet && !load">
            <h2 class="title-secondary">{{ getMyLanguage("ReferralPlan","referral-plan.title") }}</h2>

            <div class="description">
                <p>{{ getMyLanguage("ReferralPlan","referral-plan.description-01") }}</p>
                <p>{{ getMyLanguage("ReferralPlan","referral-plan.description-02") }}</p>

                <div class="bts-invite">
                    <!-- <div v-if="($store.state.phaseCurrent.phase_type == 'Private') && !$store.state.profile.is_agent" class="btn-secondary" @click="modalBecomeSeller = true">
                        {{getMyLanguage("client-space","client-space.become-seller-cta")}}
                    </div>

                    <div v-if="($store.state.phaseCurrent.phase_type == 'Private') && $store.state.profile.is_agent" class="btn-secondary" id="generateInvite" @click="openInvite()">
                        {{getMyLanguage("client-space","client-space.bonus-invite-cta")}}
                    </div> -->
                    
                    <div class="btn-url-copy" @click="copyURLRegister()">
                        <span>{{getMyLanguage("client-space","client-space.register-link")}}</span>
                    </div>

                    <a target="_blank" class="btn-more"
                        :href='"https://cdn.dneecosystem.com/docs/marketing/DNE-Presentation-Seller-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)'>
                        
                        <span>{{getMyLanguage("client-space","client-space.bonus-invite.bt-more")}}</span>
                    </a>
                </div>
            </div>


            <!-- RESUME -->
            <div class="row-resume">
                <div class="resume active">
                    <div class="cont">
                        <h3>{{ getMyLanguage("ReferralPlan","active.subtitle") }}</h3>

                        <div class="value">
                            <h4><span>{{dataReferral.Done.value.toLocaleString("pt-PT", {minimumFractionDigits: "0", maximumFractionDigits: "5"})}}</span></h4>
                        </div>

                        <p>{{ getMyLanguage("ReferralPlan","active.obs") }}</p>
                    </div>
                </div>
            
                <div class="resume inactive">
                    <div class="cont">
                        <h3>{{ getMyLanguage("ReferralPlan","inactive.subtitle") }}</h3>

                        <div class="value">
                            <h4><span>{{dataReferral.Future.value.toLocaleString("pt-PT", {minimumFractionDigits: "0", maximumFractionDigits: "5"})}}</span></h4>
                        </div>

                        <p>{{ getMyLanguage("ReferralPlan","inactive.obs") }}</p>
                    </div>
                </div>
            </div>


            <!-- SALES -->
            <div class="sales">
                <div class="item" :class="{concluded: item.activated, pending: !item.activated}" v-for="(item, index) in dataReferral.depths" :key="index">
                    <div class="level">{{ getMyLanguage("ReferralPlan","level." + item.type) }}</div>
                    
                    <div class="meta">
                        <span :class="{concluded: item.accounts.done >= item.accounts.required, pending: item.accounts.done < item.accounts.required}">
                            <strong>{{item.accounts.done}}<small>/</small>{{item.accounts.required}}</strong> {{ getMyLanguage("ReferralPlan","meta.sales") }}
                        </span>
                        
                        <span :class="{concluded: item.companies.done >= item.companies.required, pending: item.companies.done < item.companies.required}">
                            <strong>{{item.companies.done}}<small>/</small>{{item.companies.required}}</strong> {{ getMyLanguage("ReferralPlan","meta.registers") }}
                        </span>
                    </div>

                    <div class="value">
                        <h3><span>{{item.total.toLocaleString("pt-PT", {minimumFractionDigits: "0", maximumFractionDigits: "5"})}}</span></h3>
                    </div>

                    <div class="obs" v-if="(item.accounts.done < item.accounts.required) || (item.companies.done < item.companies.required)">
                        <span v-if="item.accounts.done < item.accounts.required">
                            {{ getMyLanguage("ReferralPlan","meta.obs.pending") }}: {{item.accounts.required - item.accounts.done}} {{ getMyLanguage("ReferralPlan","meta.sales") }}
                        </span>

                        <span v-if="item.companies.done < item.companies.required">
                            {{ getMyLanguage("ReferralPlan","meta.obs.pending") }}: {{item.companies.required - item.companies.done}} {{ getMyLanguage("ReferralPlan","meta.registers") }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import apiInternational from '@/config/apiInternational.js';
    import { handleError } from '@/services/errorHandler.js'

    // import axios from 'axios';
    import moment from "moment";

    export default {
        data() {
            return {
                flagSelected: "en",
                load: true,

                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,

                dataReferral: [],

                errosGet: false
            };
        },

        async mounted() {
            await apiInternational.get("/api/v2/sales/agent/sale_depths")
            .then((response) => {
                this.dataReferral = response.data;
                console.log(this.dataReferral)
                console.clear()
                
                this.load = false
            })
            .catch((error) => {
                this.load = false
                this.errosGet = error
                console.log(error)

                handleError(error, this.flagSelected);
            });
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            loginStatus() {
                if (
                    localStorage.getItem("accessToken") === undefined ||
                    localStorage.getItem("accessToken") === null
                ) {
                    window.location.href = "/";
                }
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
                return moment(value).format(format);
            },

            async copyURLRegister(){
                try {
                    // await navigator.clipboard.writeText(`${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/ico?utm_content=${this.$store.state.profile.account_id}&utm_source=dne-token&utm_campaign=ico`);
                    await navigator.clipboard.writeText(`${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/register?utm_content=${this.$store.state.profile.account_id}&utm_source=dne-token&utm_campaign=box`);
                    this.openToastCopy()
                }
                
                catch($e) {
                    this.openToastNoCopy()
                }
            },

            async openToastCopy() {
                this.$toast.success(this.getMyLanguage("client-space","instructions.copied"));
                setTimeout(this.$toast.clear, 6000)
            },
            
            
            async openToastNocopy() {
                this.$toast.success(this.getMyLanguage("client-space","instructions.nocopied"));
                setTimeout(this.$toast.clear, 6000)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    };
</script>

<style src="@/assets/scss/_referral-plan.scss" lang="scss" scoped />